import { Injectable } from "@angular/core";

const sessionStorageKey = 'sessionInfo';

export type Session = {
  email: string,
  refreshToken: string,
  emailConfirmed: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class SessionStore {

  public saveSession(session: Session): void {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(session));
  }

  public getSession(): Session {
    const persistentSessionStr = sessionStorage.getItem(sessionStorageKey);
    if (persistentSessionStr) {
      try {
        return JSON.parse(persistentSessionStr);
      } catch (e) {
        console.log('Unable to restore session info', e);
      }
    }
    return { email: '', refreshToken: '', emailConfirmed: false };
  }

  public updateSession(sessionFragment: Partial<Session>): void {
    this.saveSession({ ...this.getSession(), ...sessionFragment });
  }

  public deleteSession(): void {
    sessionStorage.removeItem(sessionStorageKey);
    sessionStorage.clear();
  }

}
