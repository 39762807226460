/**
 * coreAPI
 * API core
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentOrderInput { 
    provider: PaymentOrderInput.ProviderEnum;
    servicePackage: PaymentOrderInput.ServicePackageEnum;
}
export namespace PaymentOrderInput {
    export type ProviderEnum = 'stripe';
    export const ProviderEnum = {
        Stripe: 'stripe' as ProviderEnum
    };
    export type ServicePackageEnum = 'premium' | 'premiumWithFreeTrial' | 'storage10Gb' | 'storage20Gb' | 'storage100Gb' | 'storage200Gb' | 'notary';
    export const ServicePackageEnum = {
        Premium: 'premium' as ServicePackageEnum,
        PremiumWithFreeTrial: 'premiumWithFreeTrial' as ServicePackageEnum,
        Storage10Gb: 'storage10Gb' as ServicePackageEnum,
        Storage20Gb: 'storage20Gb' as ServicePackageEnum,
        Storage100Gb: 'storage100Gb' as ServicePackageEnum,
        Storage200Gb: 'storage200Gb' as ServicePackageEnum,
        Notary: 'notary' as ServicePackageEnum
    };
}


