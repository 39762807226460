import { Subject } from "rxjs";

export class DoOnceOnConcurrentCall<T> {

  private running: Promise<T> | false = false;

  public async run(runner: () =>Promise<T>): Promise<T> {
    if (this.running) {
      return await this.running;
    }
    const rdvSubject = new Subject<T>();
    this.running = rdvSubject.toPromise();
    let result: T;
    try {
      result = await runner();
      setTimeout(() => {
        rdvSubject.next(result);
        rdvSubject.complete();
      });
      return result;
    } catch (e) {
      setTimeout(() => {
        rdvSubject.error(e);
      });
      throw e;
    } finally {
      this.running = false;
    }
  }

}