import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ProfileRouteNames } from "../../profile/profile.routes.names";
import { RegisterRoutesNames } from "../../register/register.routes.names";
import { AuthRoutesNames } from "../auth.routes.names";
import { AuthService } from "./auth.service";


@Injectable({
  providedIn: 'root'
})
export class AuthRouterService {

  private returnUrl?: string;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  async goToLoginPage(returnUrl?: string): Promise<void> {
    this.returnUrl = returnUrl || this.returnUrl || this.router.routerState.snapshot.url;
    await this.router.navigate([AuthRoutesNames.AUTH_ROOT]);
  }

  async goToRegisterPage(returnUrl?: string): Promise<void> {
    this.returnUrl = returnUrl || this.returnUrl || this.router.routerState.snapshot.url;
    await this.router.navigate([AuthRoutesNames.AUTH_ROOT, RegisterRoutesNames.ROOT]);
  }

  async redirectAfterLogin(): Promise<void> {
    if (this.authService.requireDeviceVerification()) {
      await this.router.navigate([AuthRoutesNames.AUTH_ROOT, AuthRoutesNames.VERIFY_DEVICE]);
    } else if (await this.authService.isAccountDeleted()) {
      await this.router.navigate([ProfileRouteNames.ROOT, ProfileRouteNames.ACCOUNT_DELETED]);
    } else if (this.returnUrl) {
      await this.router.navigateByUrl(this.returnUrl);
      this.returnUrl = undefined;
    } else {
      await this.router.navigateByUrl('');
    }
  }

}