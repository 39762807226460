<app-auth-page>

  <div page-title>Confirmer mon adresse email</div>

  <form [formGroup]="formGroup" data-test=confirm-email-form (ngSubmit)="onSubmit()" class=one-column-responsive>

    <app-email-field [emailControl]="emailControl"></app-email-field>

    <mat-form-field appearance="outline" floatLabel="always" data-test=confirm-email-password>
      <mat-label>Code de validation</mat-label>
      <input matInput [formControl]="confirmCodeControl" placeholder="ex: abc01xyz" required
        data-test=confirm-email-code-input autocomplete="off" id="email">
        <mat-error *ngIf="confirmCodeControl.errors?.required">Ce champ est requis</mat-error>
        <mat-error *ngIf="confirmCodeControl.errors?.minlength || confirmCodeControl.errors?.maxlength">
          Le code de validation doit faire 8 caractères
        </mat-error>
    </mat-form-field>

    <div class="form-error" *ngIf="errorMessage">{{errorMessage}}</div>

    <div class="form-message" *ngIf="!newConfirmCodeSent">Un code de confirmation a été envoyé sur votre adresse email</div>
    <div class="form-message" *ngIf="newConfirmCodeSent">Un nouveau code de confirmation a été envoyé sur votre adresse email</div>
    <div class="form-link" *ngIf="!newConfirmCodeSent">
      <a [routerLink]="" data-test=send-new-code-link (click)=sendNewConfirmCode()>Envoyer un nouveau code</a>
    </div>

    <button type="submit" mat-flat-button color="accent" class="form-action" data-test="confirm-email-button"
      [disabled]="formGroup.invalid">Continuer</button>

  </form>

</app-auth-page>
