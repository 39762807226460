import { Notary, TestamentOutput } from 'src/sdk/solal-core-api-sdk';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { GuardedUsersAPIService } from 'src/sdk/solal-core-api-sdk/api/guardedUsersAPI.service';
import { Injectable } from "@angular/core";
import { GuardedUser } from 'src/sdk/solal-core-api-sdk/model/guardedUser';
import { BeneficiaryOutput, DocumentOutput, UploadDocumentOutput } from 'src/sdk/solal-core-api-sdk';
import { AssetOutput } from 'src/sdk/solal-core-api-sdk';
import { Asset } from '../../assets/services/assets.service';
import { GuardianState } from '@solal-tech/solal-common';
import { UserStatusService } from '../../auth/services/user-status.service';

export type GuardianNotifications = {
  accepted: GuardedUser[];
  execution: GuardedUser[];
};

@Injectable({
  providedIn: 'root'
})
export class GuardedUsersService {

  private guardedUsersMap?: Map<string, GuardedUser>;
  private guardedUsersAssetsMap = new Map<string, AssetOutput[]>();
  private guardedUsersBeneficiariesMap = new Map<string, Map<string, BeneficiaryOutput>>();
  private guardedUsersTestamentsMap = new Map<string, TestamentOutput[]>();

  constructor(
    private authService: AuthService,
    private guardedUsersApi: GuardedUsersAPIService,
    private userStatusService: UserStatusService,
  ) {
    this.authService.sessionUserObs.subscribe(user => {
      // Reset assets cache on user logout
      if (user === null) {
        this.guardedUsersMap = undefined;
        this.guardedUsersAssetsMap = new Map<string, AssetOutput[]>();
        this.guardedUsersBeneficiariesMap = new Map<string, Map<string, BeneficiaryOutput>>();
        this.guardedUsersTestamentsMap = new Map<string, TestamentOutput[]>();
      }
    });
  }

  async listGuardedUsers(refresh = false): Promise<GuardedUser[]> {
    if (this.guardedUsersMap && !refresh) {
      return Array.from(this.guardedUsersMap.values());
    }
    const guardedUsers = await this.guardedUsersApi.listGuardedUsers().toPromise();
    this.guardedUsersMap = new Map(guardedUsers.map(guardedUser => [guardedUser._id, guardedUser]));
    return guardedUsers;
  }

  async getGuardedUser(guardedUserId: string): Promise<GuardedUser | undefined> {
    if (!this.guardedUsersMap) {
      await this.listGuardedUsers();
    }
    return this.guardedUsersMap?.get(guardedUserId);
  }

  async listGuardedUserAssets(guardedUserId: string): Promise<Asset[]> {
    let assets = this.guardedUsersAssetsMap.get(guardedUserId);
    if (!assets) {
      assets = await this.guardedUsersApi.listGuardedUserAssets(guardedUserId).toPromise();
      this.guardedUsersAssetsMap.set(guardedUserId, assets);
    }
    const beneficiariesMap = await this.getGuardedUserBeneficiariesMap(guardedUserId);
    return assets.map(asset => this.populateBeneficiaries(asset, beneficiariesMap));
  }

  async getGuardedUserAsset(guardedUserId: string, assetId: string): Promise<Asset | undefined> {
    const beneficiariesMap = await this.getGuardedUserBeneficiariesMap(guardedUserId);
    const assets = await this.listGuardedUserAssets(guardedUserId);
    const asset = assets.find(asset => asset._id === assetId);
    if (asset) {
      return this.populateBeneficiaries(asset, beneficiariesMap);
    } else {
      return undefined;
    }
  }

  async getGuardedUserBeneficiariesMap(guardedUserId: string): Promise<Map<string, BeneficiaryOutput>> {
    let beneficiariesMap = this.guardedUsersBeneficiariesMap.get(guardedUserId);
    if (!beneficiariesMap) {
      const beneficiaries = await this.guardedUsersApi.listGuardedUserBeneficiaries(guardedUserId).toPromise();
      beneficiariesMap = new Map(beneficiaries.map(beneficiary => [beneficiary._id, beneficiary]));
      this.guardedUsersBeneficiariesMap.set(guardedUserId, beneficiariesMap);
    }
    return beneficiariesMap;
  }

  async listGuardedUserBeneficiaries(guardedUserId: string): Promise<BeneficiaryOutput[]> {
    return Array.from((await this.getGuardedUserBeneficiariesMap(guardedUserId)).values());
  }

  async listGuardedUserOfficialDocs(testatorId: string): Promise<DocumentOutput[]> {
    return await this.guardedUsersApi.listGuardedUserOfficialDocuments(testatorId).toPromise();
  }

  async requestGuardedUserOfficialDocUpload(guardedUserId: string, file: File): Promise<UploadDocumentOutput> {
    return await this.guardedUsersApi.createGuardedUserOfficialDocument(guardedUserId, {
      name: file.name, size: file.size, type: file.type,
    }).toPromise();
  }

  async deleteGuardedUserOfficialDoc(guardedUserId: string, document: DocumentOutput): Promise<void> {
    await this.guardedUsersApi.deleteGuardedUserOfficialDocument(guardedUserId, document.name).toPromise();
  }

  async reportGuardedUserDeath(guardedUserId: string): Promise<void> {
    await this.guardedUsersApi.reportGuardedUserDeath(guardedUserId).toPromise();
  }

  async listGuardedUserTestaments(guardedUserId: string): Promise<TestamentOutput[]> {
    if (this.guardedUsersTestamentsMap.has(guardedUserId)) {
      return this.guardedUsersTestamentsMap.get(guardedUserId)!;
    }
    const testaments = await this.guardedUsersApi.listGuardedUserTestaments(guardedUserId).toPromise();
    this.guardedUsersTestamentsMap.set(guardedUserId, testaments);
    return testaments;
  }

  async listGuardedUserAssetDocuments(testatorId: string, assetId: string): Promise<DocumentOutput[]> {
    return await this.guardedUsersApi.listGuardedUserAssetDocuments(testatorId, assetId).toPromise();
  }

  async requestGuardedUserAssetProofUpload(testatorId: string, assetId: string, file: File): Promise<UploadDocumentOutput> {
    return await this.guardedUsersApi.createGuardedUserAssetProof(testatorId, assetId, {
      name: file.name, size: file.size, type: file.type,
    }).toPromise();
  }
  async listGuardedUserAssetProofs(testatorId: string, assetId: string): Promise<DocumentOutput[]> {
    return await this.guardedUsersApi.listGuardedUserAssetProofs(testatorId, assetId).toPromise();
  }
  async deleteGuardedUserAssetProof(testatorId: string, assetId: string, document: DocumentOutput): Promise<void> {
    await this.guardedUsersApi.deleteGuardedUserAssetProof(testatorId, assetId, document.name).toPromise();
  }

  async getBeneficiary(guardedUserId: string, beneficiaryId: string): Promise<BeneficiaryOutput | undefined> {
    if (!this.guardedUsersBeneficiariesMap.has(guardedUserId)) {
      await this.listGuardedUserBeneficiaries(guardedUserId);
    }
    return this.guardedUsersBeneficiariesMap?.get(guardedUserId)?.get(beneficiaryId);
  }

  async getNotifications(): Promise<GuardianNotifications> {
    const guardedUsers = await this.listGuardedUsers();
    return {
      accepted: guardedUsers.filter(guardedUser => guardedUser.status === GuardianState.accepted),
      execution: guardedUsers.filter(guardedUser => guardedUser.status === GuardianState.execution),
    };
  }

  private populateBeneficiaries(asset: AssetOutput, beneficiariesMap: Map<string, BeneficiaryOutput>): Asset {
    const beneficiaries: BeneficiaryOutput[] = asset.beneficiariesIds
      ?.map(beneficiaryId => beneficiariesMap.get(beneficiaryId))
      .filter(beneficiary => beneficiary !== undefined) as (BeneficiaryOutput[])
      || [];
    return {
      ...asset,
      beneficiaries,
    };
  }

  async fullFillGuardedUserAssetWill(testatorId: string, assetId: string): Promise<void> {
    await this.guardedUsersApi.fullFillGuardedUserAssetWill(testatorId, assetId).toPromise();
    this.guardedUsersAssetsMap.delete(testatorId);
    this.guardedUsersTestamentsMap.delete(testatorId);
  }

  async getGuardedUserNotary(testatorId: string): Promise<Notary|null> {
    return (await this.guardedUsersApi.getGuardedUserNotary(testatorId).toPromise()).notary ?? null;
  }

  async closeExecution(testatorId: string): Promise<GuardedUser> {
    const guardedUser = await this.guardedUsersApi.closeGuardedUserExecution(testatorId).toPromise();
    this.guardedUsersMap?.set(testatorId, guardedUser);
    void this.userStatusService.refreshUserStatus();
    return guardedUser;
  }

}
