import { Injectable } from "@angular/core";
import { RestorationKey, Secret, UserKeys, UsersAPIService, VaultAPIService } from "src/sdk/solal-core-api-sdk";
import { customAlphabet } from "nanoid";
import { BehaviorSubject, Subject } from "rxjs";
import { first, skipWhile } from "rxjs/operators";
import { authRequired } from './../../auth/services/auth.service';
import { AuthService } from "../../auth/services/auth.service";
import { UserStatusService } from "../../auth/services/user-status.service";
import {
  arrayBufferToBase64, base64ToArrayBuffer, decryptMessage, deriveInitialVectorFromId, deriveUserMasterKey,
  deriveUserRestorationKey,
  encryptMessage, exportPublicKey, exportUserMasterKey, genAESKey, generateInitialVector, genRSAKeyPair,
  hashUserPassword, importPublicKey, importUserMasterKey, unwrapAESKey, unwrapRSAKey, wrapAESKey, wrapRSAKey
} from "../utils/crypto-utils";
import { GuardiansService } from './../../guardians/services/guardians.service';


export const MasterKeyNotInitializedError = new Error('MasterKeyNotInitializedError');
export const InvalidMasterKeyError = new Error('InvalidMasterKeyError');
export const NoKeyForGivenVault = new Error('NoKeyForGivenVault');
export const NoRestorationKey = new Error('NoRestorationKey');
export const InvalidRestorationKey = new Error('InvalidRestorationKey');
export const NoUserKeysError = new Error('NoUserKeysError');

const sessionStorageUserId = 'userId';
const sessionStorageMasterKey = 'masterKey';

const restorationKeyAlphabet = '346789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRTWXY'; // remove ambiguous 0O1lI2Z5SUV
const restorationKeyLength = 20;

@Injectable({
  providedIn: 'root'
})
export class VaultService {

  private keyGenInProgress = new BehaviorSubject<boolean>(false);
  public readonly keyGenInProgressObs = this.keyGenInProgress.asObservable();

  private restorationRequired = new Subject<void>();
  public readonly restorationRequiredObs = this.restorationRequired.asObservable();

  private userId?: string;

  private userMasterKey?: CryptoKey; // AES symetric key derived from user password

  private vaultKeys = new Map<string, CryptoKey>(); // AES keys used to ciphered vaults secrets

  private restorationKeyGenerationInProgress = false;

  constructor(
    private authService: AuthService,
    private vaultApi: VaultAPIService,
    private usersApi: UsersAPIService,
    private guardiansService: GuardiansService,
    private userStatusService: UserStatusService,
  ) {
    this.authService.sessionUserObs.subscribe(user => {
      if (user === null) {
        this.reinit();
      }
    });
    void this.restoreUserMasterKey();
  }

  async setUserMasterKey(user: { _id: string, password: string }): Promise<void> {
    this.userId = user._id;
    this.userMasterKey = await deriveUserMasterKey(user);
    sessionStorage.setItem(sessionStorageUserId, this.userId);
    sessionStorage.setItem(sessionStorageMasterKey, await exportUserMasterKey(this.userMasterKey));
    await this.attemptToretrieveUserKeysFromServer();
  }

  async attemptToretrieveUserKeysFromServer(): Promise<void> {
    if (this.userId && this.userMasterKey && await this.authService.isLoggedIn()) {
      await this.retrieveUserKeys(this.userId, this.userMasterKey);
      await this.shareVaultKeyWithGuardians();
    }
  }

  async encrypt(msg: string, vaultId?: string): Promise<Secret>;
  async encrypt(msg: undefined, vaultId?: string): Promise<undefined>;
  async encrypt(msg: string|undefined, vaultId?: string): Promise<Secret|undefined>;
  async encrypt(msg: string|undefined, vaultId?: string): Promise<Secret|undefined> {
    if (msg === undefined || msg === '') {
      return undefined;
    }
    await this.restoreUserMasterKey();
    vaultId = vaultId || this.getUserId();
    const iv = generateInitialVector();
    const encrypted = await encryptMessage(await this.getVaultKey(vaultId), msg, iv);
    return { vaultId, iv: arrayBufferToBase64(iv), encrypted };
  }

  async decrypt(secret: Secret): Promise<string>;
  async decrypt(secret: undefined): Promise<undefined>;
  async decrypt(secret: Secret|undefined): Promise<string|undefined>;
  async decrypt(secret: Secret|undefined): Promise<string|undefined> {
    if (!secret) {
      return undefined;
    }
    if (secret.encrypted === '') {
      return '';
    }
    await this.restoreUserMasterKey();
    const iv = base64ToArrayBuffer(secret.iv);
    return await decryptMessage(await this.getVaultKey(secret.vaultId), secret.encrypted, iv);
  }

  async unwrapKeyWithPrivateKey(wrappedKey: string): Promise<CryptoKey> {
    if (!this.userId || !this.userMasterKey) {
      throw MasterKeyNotInitializedError;
    }
    const optUserKeys = await this.vaultApi.getUserKeys().toPromise();
    if (!optUserKeys.keys) {
      throw NoUserKeysError;
    }
    const userKeys = optUserKeys.keys;
    const userInitialVector = await deriveInitialVectorFromId(this.userId);
    const userPrivateKey = await this.unwrapPvKeyWithMasterKey(userKeys.privateKeyEncWithPwd, this.userMasterKey, userInitialVector);
    return unwrapAESKey(wrappedKey, userPrivateKey);
  }

  async generateRestorationKey(): Promise<string> {
    if (!this.userId) {
      throw MasterKeyNotInitializedError;
    }
    this.restorationKeyGenerationInProgress = true;
    try {
      const restorationKey = customAlphabet(restorationKeyAlphabet, restorationKeyLength)();
      const userRestorationKey = await deriveUserRestorationKey({ userId: this.userId, restorationKey });
      if (this.keyGenInProgress.value) {
        // defer the restoration key creation to the end of the user keys creation
        this.keyGenInProgressObs.pipe(
          skipWhile(inProgress => inProgress),
          first(),
        ).subscribe(async () => {
          await this.createRestorationKey(userRestorationKey);
        });
      } else {
        await this.getVaultKey(this.userId); // Force generation of ciphering keys if not already generated
        await this.createRestorationKey(userRestorationKey);
      }
      return this.addDashEveryFourCaracters(restorationKey);
    } finally {
      this.restorationKeyGenerationInProgress = false;
    }
  }

  async updatePvKeyProtection(restorationKey: string): Promise<void> {
    if (!this.userId || !this.userMasterKey) {
      throw MasterKeyNotInitializedError;
    }
    const userRestorationKey = await deriveUserRestorationKey({ userId: this.getUserId(), restorationKey: this.removeDash(restorationKey) });
    const userKeys = await this.getUserKeys();
    const userInitialVector = await deriveInitialVectorFromId(this.userId);
    const decryptResult = await this.decryptPrivateKeyWithRestorationKey(userKeys, userInitialVector, userRestorationKey);
    const pvKeyEncWithPwd = await wrapRSAKey(decryptResult.privatekey, this.userMasterKey, userInitialVector);
    await this.vaultApi.updatePvKeyEncWithPwd({ pvKeyEncWithPwd }).toPromise();
  }

  async generateNewUserkeysAndLooseAccessToAllSecrets(): Promise<void> {
    await this.generateUserKeys(this.getUserId(), this.getUserMasterKey(), true);
  }

  async isRestorationKeyValid(restorationKey: string): Promise<string|undefined> {
    try {
      const userRestorationKey = await deriveUserRestorationKey({ userId: this.getUserId(), restorationKey: this.removeDash(restorationKey) });
      const userKeys = await this.getUserKeys();
      const userInitialVector = await deriveInitialVectorFromId(this.getUserId());
      return (await this.decryptPrivateKeyWithRestorationKey(userKeys, userInitialVector, userRestorationKey)).restorationKeyId;
    } catch (e) {
      return undefined;
    }
  }

  async getVaultKeyEncWithGuardianPUBKey(guardianPUBKey: string): Promise<string> {
    const publicKey = await importPublicKey(guardianPUBKey);
    return await wrapAESKey(await this.getVaultKey(this.getUserId()), publicKey);
  }

  async shareVaultKeyWithGuardians(): Promise<void> {
    const guardians = await this.guardiansService.listGuardians();
    for (const guardian of guardians) {
      if (guardian.publicKey) {
        const encryptedVaultKey = await this.getVaultKeyEncWithGuardianPUBKey(guardian.publicKey);
        await this.guardiansService.setGuardianKey(guardian, encryptedVaultKey);
      }
    }
  }

  async generateVaultKey(): Promise<void>{
    await this.getVaultKey(this.getUserId());
  }

  async getRestorationKeys(): Promise<RestorationKey[]> {
    try {
      const userKeys = await this.getUserKeys();
      return userKeys.restorationKeys;
    } catch (e) {
      if (e === NoUserKeysError) {
        return [];
      }
      throw e;
    }
  }

  isRestorationKeyGenerationInProgress(): boolean {
    return this.restorationKeyGenerationInProgress;
  }

  async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    const userId = this.getUserId();
    const email = (await this.authService.getSessionUser())?.email;
    if (!email) {
      throw authRequired;
    }
    const currentUserMasterKey = this.getUserMasterKey();
    let pvKeyEncWithPwd: string|undefined;

    const userMasterKey = await deriveUserMasterKey({
      _id: userId,
      password: newPassword,
    });

    const optUserKeys = await this.vaultApi.getUserKeys().toPromise();
    if (optUserKeys.keys) {
      const userKeys = optUserKeys.keys;
      const userInitialVector = await deriveInitialVectorFromId(userId);
      const userPrivateKey = await this.unwrapPvKeyWithMasterKey(userKeys.privateKeyEncWithPwd, currentUserMasterKey, userInitialVector);
      pvKeyEncWithPwd = await wrapRSAKey(userPrivateKey, userMasterKey, userInitialVector);
    }

    await this.usersApi.changePassword({
      currentPassword: await hashUserPassword({ email, password: currentPassword }),
      newPassword: await hashUserPassword({ email, password: newPassword }),
      pvKeyEncWithPwd,
    }).toPromise();

    this.userMasterKey = userMasterKey;
    sessionStorage.setItem(sessionStorageMasterKey, await exportUserMasterKey(this.userMasterKey));
  }

  private async restoreUserMasterKey(): Promise<void> {
    if (!this.userId || !this.userMasterKey) {
      this.userId = sessionStorage.getItem(sessionStorageUserId) || undefined;
      const exportedMasterKey = sessionStorage.getItem(sessionStorageMasterKey);
      if (exportedMasterKey) {
        this.userMasterKey = await importUserMasterKey(exportedMasterKey);
      }
    }
  }

  private reinit(): void {
    this.userId = undefined;
    this.userMasterKey = undefined;
    this.vaultKeys.clear();
    //We have removed this 2 lines since the masterkey was deleted by this when the user has to verify it's device
    // When loging out, the session storage is emptied by another service, so no security risks
    //sessionStorage.removeItem(sessionStorageUserId);
    //sessionStorage.removeItem(sessionStorageMasterKey);
  }

  private async getVaultKey(vaultId: string): Promise<CryptoKey> {
    let vaultKey = this.vaultKeys.get(vaultId);
    if (!vaultKey) {
      await this.retrieveUserKeys(this.getUserId(), this.getUserMasterKey(), true);
      vaultKey = this.vaultKeys.get(vaultId);
      if (!vaultKey) {
        throw NoKeyForGivenVault;
      }
    }
    return vaultKey;
  }

  private async retrieveUserKeys(userId: string, userMasterKey: CryptoKey, generateIfNone = false): Promise<boolean> {
    try {
      const optUserKeys = await this.vaultApi.getUserKeys().toPromise();
      if (!optUserKeys.keys) {
        if (generateIfNone) {
          await this.generateUserKeys(userId, userMasterKey);
          return true;
        }
        return false;
      }
      const userKeys = optUserKeys.keys;
      const userInitialVector = await deriveInitialVectorFromId(userId);
      const userPrivateKey = await this.unwrapPvKeyWithMasterKey(userKeys.privateKeyEncWithPwd, userMasterKey, userInitialVector);
      for (const protectedVaulKey of userKeys.vaultKeys) {
        const vaultKey = await unwrapAESKey(protectedVaulKey.keyEncWithUserKey, userPrivateKey);
        this.vaultKeys.set(protectedVaulKey.vaultId, vaultKey);
      }
      return true;
    } catch (e) {
      if (e === InvalidMasterKeyError) {
        this.restorationRequired.next();
      } else {
        console.log('retrieveUserKeys error', e);
      }
      return false;
    }
  }

  async generateUserAsymetricKeys(): Promise<{privateKeyProtected: string, publicKey: string }> {
    if (!this.userId || !this.userMasterKey) {
      throw MasterKeyNotInitializedError;
    }
    const userKeyPair = await genRSAKeyPair();
    const userInitialVector = await deriveInitialVectorFromId(this.userId);
    const privateKeyProtected = await wrapRSAKey(userKeyPair.privateKey, this.userMasterKey, userInitialVector);
    const publicKey = await exportPublicKey(userKeyPair.publicKey);
    return {
      privateKeyProtected,
      publicKey,
    };
  }

  async setNewKeys(keys: { publicKey: string, privateKeyProtected: string, encryptedVaultKey: string }): Promise<void> {
    if (!this.userId || !this.userMasterKey) {
      throw MasterKeyNotInitializedError;
    }
    await this.vaultApi.setUserKeys({
      privateKeyEncWithPwd: keys.privateKeyProtected,
      publicKey: keys.publicKey,
      vaultKeys: [ { vaultId: this.userId, keyEncWithUserKey: keys.encryptedVaultKey } ],
      restorationKeys: [],
    }, true).toPromise();
    await this.getVaultKey(this.userId);
    await this.shareVaultKeyWithGuardians();
  }

  private async generateUserKeys(userId: string, userMasterKey: CryptoKey, overwrite = false): Promise<void> {
    this.keyGenInProgress.next(true);
    try {
      const userKeyPair = await genRSAKeyPair();
      const userInitialVector = await deriveInitialVectorFromId(userId);
      const privateKeyEncWithPwd = await wrapRSAKey(userKeyPair.privateKey, userMasterKey, userInitialVector);
      const publicKey = await exportPublicKey(userKeyPair.publicKey);
      const vaultKey = await genAESKey();
      const protectedVaultKey = await wrapAESKey(vaultKey, userKeyPair.publicKey);
      await this.vaultApi.setUserKeys({
        privateKeyEncWithPwd,
        publicKey,
        vaultKeys: [ { vaultId: userId, keyEncWithUserKey: protectedVaultKey } ],
        restorationKeys: [],
      }, overwrite).toPromise();
      this.vaultKeys.set(userId, vaultKey);
      await this.shareVaultKeyWithGuardians();
    } finally {
      this.keyGenInProgress.next(false);
    }
  }

  private async decryptPrivateKeyWithRestorationKey(userKeys: UserKeys, userIV: ArrayBuffer,
    userRestorationKey: CryptoKey): Promise<{ privatekey: CryptoKey, restorationKeyId: string}> {

    for (const restorationKey of userKeys.restorationKeys) {
      try {
        return {
          privatekey: await unwrapRSAKey(restorationKey.pvKeyEncWithRestorKey, userRestorationKey, userIV),
          restorationKeyId: restorationKey._id!,
        };
      } catch (e) {
        console.log('pvKey unwrap with restoration key attempt', e);
      }
    }
    throw InvalidRestorationKey;
  }

  private async createRestorationKey(userRestorationKey: CryptoKey): Promise<void> {
    if (!this.userId || !this.userMasterKey) {
      throw MasterKeyNotInitializedError;
    }
    const userKeys = await this.getUserKeys();
    const userInitialVector = await deriveInitialVectorFromId(this.userId);
    const userPrivateKey = await unwrapRSAKey(userKeys.privateKeyEncWithPwd, this.userMasterKey, userInitialVector);
    const pvKeyEncWithRestorKey = await wrapRSAKey(userPrivateKey, userRestorationKey, userInitialVector);
    await this.vaultApi.createRestorationKey({ pvKeyEncWithRestorKey }).toPromise();
    void this.userStatusService.refreshUserStatus();
  }

  async deleteRestorationKey(restorationKeyId: string): Promise<void> {
    await this.vaultApi.deleteRestorationKey(restorationKeyId).toPromise();
    void this.userStatusService.refreshUserStatus();
  }

  private async getUserKeys(): Promise<UserKeys> {
    const optUserKeys = await this.vaultApi.getUserKeys().toPromise();
    if (!optUserKeys.keys) {
      throw NoUserKeysError;
    }
    return optUserKeys.keys;
  }

  private async unwrapPvKeyWithMasterKey(pvKeyEncWithMasterKey: string, masterKey: CryptoKey,
    userInitialVector: ArrayBuffer): Promise<CryptoKey> {

    try {
      return await unwrapRSAKey(pvKeyEncWithMasterKey, masterKey, userInitialVector);
    } catch (e) {
      const errorName = (e as DOMException).name;
      if (!errorName || errorName === 'NotSupported') {
        throw e;
      }
      throw InvalidMasterKeyError;
    }
  }

  private addDashEveryFourCaracters(str: string): string {
    return str.replace(/.{4}/g, '$&-').replace(/-$/,'');
  }

  private removeDash(str: string): string {
    return str.replace(/-/g, '');
  }

  private getUserId(): string {
    if (this.userId) {
      return this.userId;
    }
    throw MasterKeyNotInitializedError;
  }

  private getUserMasterKey(): CryptoKey {
    if (this.userMasterKey) {
      return this.userMasterKey;
    }
    throw MasterKeyNotInitializedError;
  }

}
