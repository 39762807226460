import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-left-panel-citation',
  templateUrl: './left-panel-citation.component.html',
  styleUrls: [ './left-panel-citation.component.scss' ]
})
export class LeftPanelCitationComponent {
  @Input() displaySolaltechAdvantages?: boolean = false;
  @Input() mobile?: boolean = false;

  constructor(
  ) { }

  protected readonly Input = Input;
}
