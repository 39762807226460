<app-auth-page>

  <div page-title>Mot de passe oublié</div>

  <app-msg-box msgType="warning">
    Attention, vos secrets sauvegardés sur la plateforme Solal sont protégés avec votre mot de passe.
    Si vous avez perdu celui-ci et que vous n'avez pas de clé de restauration, vos secrets seront perdus.
  </app-msg-box>

  <div *ngIf="emailSent">
    Un lien pour réinitialiser votre mot de passe a été envoyé à votre adresse email "{{emailControl.value}}".
  </div>

  <form *ngIf="!emailSent" [formGroup]="formGroup" data-test=forgotten-password-form (ngSubmit)="onSubmit()" class=one-column-responsive>

    <app-email-field [emailControl]="emailControl" data-test=forgotten-password-email></app-email-field>

    <div class="form-error" *ngIf="errorMessage">{{errorMessage}}</div>

    <button type="submit" mat-flat-button color="accent" class="form-action" data-test="forgotten-password-button"
      [disabled]="formGroup.invalid">Continuer</button>

    <div class="form-link"><a [routerLink]="signinLink" data-test=login-link>Revenir sur l'écran de connexion</a></div>

  </form>

</app-auth-page>