import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteSize'
})
export class ByteSizePipe extends DecimalPipe implements PipeTransform {
  private kb = 1_000;
  private mb = 1_000 * this.kb;
  private gb = 1_000 * this.mb;

  constructor(@Inject(LOCALE_ID) _locale: string) {
    super(_locale);
  }

  transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  transform(value: number | string | null | undefined): string | null {
    if (value === undefined || value === null) {
      return 'NK';
    }
    if (typeof value === 'string') {
      value = parseInt(value);
    }
    if (isNaN(value)) {
      return 'NK';
    }
    if (value < 0) {
      return "NK";
    }
    if (Math.abs(value) < this.kb) {
      return super.transform(value, "1.0-1") + ' o';
    }
    if (Math.abs(value) < this.mb) {
      return super.transform(value / this.kb, "1.0-1") + ' Ko';
    }
    if (Math.abs(value) < this.gb) {
      return super.transform(value / this.mb, "1.0-1") + ' Mo';
    }
    return super.transform(value / this.gb, "1.0-1") + ' Go';
  }
}
