import { HttpErrorResponse } from "@angular/common/http";
import { AppError } from "src/sdk/solal-core-api-sdk";
import { defaultAppErrorCodesMap, ErrorFormator, fallbackErrorMsg } from "./error-msg";

export function errorToUserString(err: any, errorCodesMap?: Map<string, (string|ErrorFormator)>, defaultMsg = fallbackErrorMsg): string {
  console.log('error converted to user message', err);
  return getErrorMsg(err, errorCodesMap)
    || getErrorMsg(err, defaultAppErrorCodesMap)
    || getAppErrorMsg(err, errorCodesMap)
    || getAppErrorMsg(err, defaultAppErrorCodesMap)
    || getHttpErrorMsg(err, errorCodesMap)
    || getHttpErrorMsg(err, defaultAppErrorCodesMap)
    || defaultMsg;
}

function getErrorMsg(err: any, errorCodesMap?: Map<string, (string|ErrorFormator)>): string|undefined {
  const errStr = err?.message;
  if (errStr) {
    return applyFormator(errStr, errorCodesMap, err.data);
  }
  return undefined;
}

function getHttpErrorMsg(err: any, errorCodesMap?: Map<string, (string|ErrorFormator)>): string|undefined {
  if (err instanceof HttpErrorResponse) {
    return applyFormator(err.statusText, errorCodesMap) // tries to map http text status like 'Not Found' or 'Forbidden'
      || applyFormator(String(err.status), errorCodesMap); // tries to map http error code like '404' or '500'
  }
  return undefined;
}

function getAppErrorMsg(err: any, errorCodesMap?: Map<string, (string|ErrorFormator)>): string|undefined {
  if (err && isAppError(err.error)) {
    return applyFormator(err.error.code, errorCodesMap, err.error.data);
  }
  return undefined;
}

export function getAppErrorCode(err: any): string {
  return err instanceof HttpErrorResponse && isAppError(err.error) ? err.error.code : '';
}

function isAppError(e: unknown): e is AppError {
  return e !== null && e !== undefined
    && Object.prototype.hasOwnProperty.call(e, 'code')
    && Object.prototype.hasOwnProperty.call(e, 'message');
}

function applyFormator(errCode: string, errorsMessages?: Map<string, (string|ErrorFormator)>, errData?: { [key: string]: object; }): string|undefined {
  if (errorsMessages) {
    const messageFormator = errorsMessages.get(errCode);
    if (messageFormator) {
      if (typeof messageFormator === 'string') {
        return messageFormator;
      } else {
        return messageFormator(errData || {});
      }
    }
  }
  return undefined;
}
