import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ProfileRouteNames } from '../../profile/profile.routes.names';
import { AuthService } from '../services/auth.service';
import { Role, RoleService } from '../services/role.service';

@Injectable({
  providedIn: 'root'
})
export class GuardianGuard implements CanActivate {

  constructor(
    private roleService: RoleService,
    private authService: AuthService,
    private router: Router,
  ) { }

  async canActivate(): Promise<boolean> {
    if (await this.authService.isAccountDeleted()) {
      await this.router.navigate([ProfileRouteNames.ROOT, ProfileRouteNames.ACCOUNT_DELETED]);
      return false;
    }
    this.roleService.changeRole(Role.guardian);
    return true;
  }

}