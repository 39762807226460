import { LoginOutput } from 'src/sdk/solal-core-api-sdk/model/loginOutput';
import { Injectable } from '@angular/core';
import { UsersAPIService } from 'src/sdk/solal-core-api-sdk';
import {Observable, ReplaySubject} from 'rxjs';
import { DoOnceOnConcurrentCall } from '../../core/utils/concurrent';
import { SessionTimer } from './session-timer';
import { SessionStore } from './session-store';
import { accessTokenExpirationDelayInMS, notifySessionWillExpireDelayInMs, sessionExpirationDelayInMs } from './auth.conf';
import { hashUserPassword } from '../../vault/utils/crypto-utils';
import { getAppErrorCode } from '../../core/components/error-dialog/error-msg-formator';
import { TwoFactors } from './two-factors';
import { hasPermission, SessionUser } from './session-user';
import { PermissionFlag } from '@solal-tech/solal-common';

type AccessTokenContainer = {
  expireTimestamp: number;
  token: string;
};

export const unsupportedLoginResponse = new Error('UnsupportedLoginResponse');
export const authRequired = new Error('authRequired');

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private sessionUserSub = new ReplaySubject<SessionUser|null>(1);
  public readonly sessionUserObs = this.sessionUserSub.asObservable();

  private sessionUser: SessionUser|null|undefined; // undefined when not initialized, null when logout
  private accessToken?: AccessTokenContainer;

  private refreshMutex = new DoOnceOnConcurrentCall<void>();

  private sessionTimer = new SessionTimer(notifySessionWillExpireDelayInMs, sessionExpirationDelayInMs);

  constructor(
    private usersAPI: UsersAPIService,
    private sessionStore: SessionStore,
    private twoFactors: TwoFactors,
  ) { }

  async isLoggedIn(): Promise<boolean> {
    return await this.getSessionUser() !== null;
  }

  async getSessionUser(): Promise<SessionUser|null> {
    const loadedSessionUser = localStorage.getItem('sessionUser');
    if (loadedSessionUser !== undefined && loadedSessionUser != null && (this.sessionUser === undefined || this.sessionUser === null)) {
      this.sessionUser = JSON.parse(loadedSessionUser);
      this.sessionUserSub.next(this.sessionUser!);
    }

    const loadedAccessToken = localStorage.getItem('accessToken');
    if (loadedAccessToken !== undefined && loadedAccessToken != null) {
      this.accessToken = JSON.parse(loadedAccessToken);
    }
    await this.refreshSessionIfRequire();
    return this.sessionUser!;
  }

  async getAccessToken(): Promise<string|undefined> {
    await this.refreshSessionIfRequire();
    return this.accessToken?.token;
  }

  async register(user: { email: string, firstName: string, lastName: string, phoneNumber: string, postalAddress?: string, password: string }, notaryInvitation?: string): Promise<SessionUser> {
    const registerOutput = await this.usersAPI.register({
      firstName: user.firstName.trim(),
      lastName: user.lastName.trim(),
      email: user.email,
      phoneNumber: user.phoneNumber.trim(),
      postalAddress: user.postalAddress?.trim(),
      password: await hashUserPassword(user),
      notaryInvitation,
    }).toPromise();
    this.sessionStore.saveSession({
      email: registerOutput.email,
      refreshToken: registerOutput.refreshToken,
      emailConfirmed: false,
    });
    return registerOutput;
  }

  async registerAsNotary(user: { email: string, password: string }): Promise<SessionUser> {
    const registerOutput = await this.usersAPI.registerAsNotary({
      email: user.email,
      password: await hashUserPassword(user),
    }).toPromise();
    this.sessionStore.saveSession({
      email: registerOutput.email,
      refreshToken: registerOutput.refreshToken,
      emailConfirmed: false,
    });
    return registerOutput;
  }

  async confirmEmail(params: { email: string, confirmationCode: string }): Promise<SessionUser|null> {
    await this.usersAPI.confirmEmail(params).toPromise();
    this.sessionStore.updateSession({ emailConfirmed: true });
    await this.refreshSession();
    return this.sessionUser!;
  }

  async sendNewConfirmCode(params: { email: string }): Promise<void> {
    await this.usersAPI.sendNewConfirmCode({ currentEmail: params.email }).toPromise();
  }

  async initiateEmailChange(params: { newEmail: string, password: string }): Promise<void> {
    const currentEmail = this.sessionUser?.email;
    if (!currentEmail) {
      throw authRequired;
    }
    this.twoFactors.updateEmailAddress(currentEmail, params.newEmail);
    await this.usersAPI.sendNewConfirmCode({
      currentEmail,
      newEmail: params.newEmail,
      password: await hashUserPassword({ email: currentEmail, password: params.password }),
    }).toPromise();
  }

  async updateUserInfo(params: {
    firstName: string, lastName: string, phoneNumber: string,
    postalAddress?: string, birthdate?: string, birthCountry?: string, birthplace?: string,
    currentPassword: string, newEmail: string, emailConfirmationCode?: string,
  }): Promise<SessionUser> {
    const userId = this.sessionUser?._id;
    const session = this.sessionStore.getSession();
    if (!session || !userId) {
      throw authRequired;
    }
    const updateUserInfoResponse = await this.usersAPI.updateUserInfo({
      _id: userId,
      firstName: params.firstName,
      lastName: params.lastName,
      phoneNumber: params.phoneNumber,
      postalAddress: params.postalAddress,
      birthdate: params.birthdate,
      birthCountry: params.birthCountry,
      birthplace: params.birthplace,
      emailConfirmationCode: params.emailConfirmationCode,
      currentPassword: await hashUserPassword({ email: session.email, password: params.currentPassword }),
      newPassword: await hashUserPassword({ email: params.newEmail, password: params.currentPassword }),
      refreshToken: session.refreshToken,
    }).toPromise();
    return this.processLoginResponse(updateUserInfoResponse);
  }

  async login(user: { email: string, password: string }): Promise<SessionUser> {
    try {
      const loginResponse = await this.usersAPI.login({
        email: user.email,
        password: await hashUserPassword(user),
        deviceToken: this.twoFactors.getConfirmedDeviceToken(user.email),
      }).toPromise();
      return this.processLoginResponse(loginResponse);
    } catch (err) {
      if (['badDeviceToken', 'deviceTokenUnknownOrExpired'].includes(getAppErrorCode(err))) {
        this.twoFactors.deleteConfirmedDeviceToken(user.email);
        return await this.login(user);
      } else {
        throw err;
      }
    }
  }

  async sendNewDeviceCode(): Promise<void> {
    await this.usersAPI.sendNewDeviceCode(this.twoFactors.getInProgressData()).toPromise();
  }

  async verifyDevice(params: { code: string }, saveDevice = true): Promise<SessionUser> {
    const twoFactorsData = this.twoFactors.getInProgressData();
    const response = await this.usersAPI.verifyDevice({ ...params, deviceToken: twoFactorsData.deviceToken }).toPromise();
    if (saveDevice){
      this.twoFactors.persistConfirmedDeviceToken(twoFactorsData);
    }
    this.twoFactors.clearInProgressData();
    return this.processLoginResponse(response);
  }

  async logout(): Promise<void> {
    const refreshToken = this.sessionStore.getSession().refreshToken;
    this.sessionStore.deleteSession();
    this.accessToken = undefined;
    this.setSessionUser(null);
    this.sessionTimer.cancel();
    if (refreshToken) {
      await this.usersAPI.logout({ refreshToken }).toPromise();
    }
  }

  getPersistedEmail(): string|undefined {
    return this.sessionStore.getSession()?.email;
  }

  markAccessTokenAsExpired(): void {
    if (this.accessToken) {
      this.accessToken.expireTimestamp = 0;
    }
  }

  async refreshSession(): Promise<void> {
    // Calling refresh session several time concurrently may imply not storing the last
    // token version (and is not optimized), so we use a "do once mutex" in order to
    // call it only one time and share the result between all the caller.
    await this.refreshMutex.run(async () => {
      const session = this.sessionStore.getSession();
      if (session.refreshToken && session.emailConfirmed) {
        try {
          const refreshResponse = await this.usersAPI.refreshToken({ refreshToken: session.refreshToken }).toPromise();
          this.sessionStore.updateSession({ refreshToken: refreshResponse.refreshToken });
          this.accessToken = this.buildAccessTokenContainer(refreshResponse.accessToken);
          localStorage.setItem("accessToken", JSON.stringify(this.accessToken));
          this.setSessionUser(this.cloneSessionUser(refreshResponse));
          this.sessionTimer.restart();
        } catch (err) {
          console.log('Unable to refresh the session', err);
          await this.logout();
        }
      } else {
        this.setSessionUser(null);
      }
    });
  }

  sessionWillExpireObs(): Observable<void> {
    return this.sessionTimer.notificationObs();
  }

  sessionHasExpiredObs(): Observable<void> {
    return this.sessionTimer.expirationObs();
  }

  async forgottenPassword(params: { email: string }): Promise<void> {
    await this.usersAPI.forgottenPassword(params).toPromise();
  }

  async resetPassword(params: { email: string, resetPasswordToken: string, newPassword: string }): Promise<SessionUser> {
    await this.usersAPI.resetPassword({
      email: params.email,
      resetPasswordToken: params.resetPasswordToken,
      newPassword: await hashUserPassword({ email: params.email, password: params.newPassword }),
    }).toPromise();
    return await this.login({ email: params.email, password: params.newPassword });
  }

  requireDeviceVerification(): boolean {
    return this.twoFactors.isInProgress();
  }

  async hasPermission(permission: PermissionFlag): Promise<boolean> {
    return hasPermission(await this.getSessionUser(), permission);
  }

  async isAccountDeleted(): Promise<boolean> {
    return await this.hasPermission(PermissionFlag.CanExportData)
      && !await this.hasPermission(PermissionFlag.TestatorSpaceAccess);
  }

  private processLoginResponse(loginResponse: LoginOutput): SessionUser {
    if (loginResponse.accessToken && loginResponse.refreshToken) {
      this.accessToken = this.buildAccessTokenContainer(loginResponse.accessToken);
      this.sessionStore.saveSession({
        email: loginResponse.email,
        refreshToken: loginResponse.refreshToken,
        emailConfirmed: true,
      });
      localStorage.setItem("accessToken", JSON.stringify(this.accessToken));
      this.setSessionUser(loginResponse);
      this.sessionTimer.restart();
    } else if (loginResponse.deviceToken) {
      this.twoFactors.initProcess({ email: loginResponse.email, deviceToken: loginResponse.deviceToken });
    } else {
      throw unsupportedLoginResponse;
    }
    return this.cloneSessionUser(loginResponse);
  }

  private setSessionUser(sessionUser: SessionUser|null): void {
    const sessionUserClone = sessionUser && this.cloneSessionUser(sessionUser);
    if (JSON.stringify(sessionUserClone) !== JSON.stringify(this.sessionUser)) {
      this.sessionUser = sessionUserClone;
      this.sessionUserSub.next(sessionUserClone);
    }
    localStorage.setItem("sessionUser", JSON.stringify(this.sessionUser));
  }

  private cloneSessionUser(sessionUser: SessionUser): SessionUser {
    return {
      // Avoid propagation of other attributes by creating a new instance
      _id: sessionUser._id,
      email: sessionUser.email,
      permissions: sessionUser.permissions,
      firstName: sessionUser.firstName,
      lastName: sessionUser.lastName,
      phoneNumber: sessionUser.phoneNumber,
      postalAddress: sessionUser.postalAddress,
      birthdate: sessionUser.birthdate,
      birthCountry: sessionUser.birthCountry,
      birthplace: sessionUser.birthplace,
    };
  }

  private buildAccessTokenContainer(token: string): AccessTokenContainer {
    return { token, expireTimestamp: Date.now() + accessTokenExpirationDelayInMS };
  }

  private async refreshSessionIfRequire(): Promise<void> {
    const initialized = this.sessionUser !== undefined;
    const loggedIn = !!this.sessionUser;
    const accessTokenValid = this.accessToken && this.accessToken.expireTimestamp > Date.now();
    if (!initialized || (loggedIn && !accessTokenValid)) {
      await this.refreshSession();
    }
  }

}
