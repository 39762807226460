import { BeneficiariesService } from 'src/app/modules/beneficiaries/services/beneficiaries.service';
import { Injectable } from '@angular/core';
import { AssetInput, AssetOutput, AssetAPIService, BeneficiaryOutput, Secret } from 'src/sdk/solal-core-api-sdk';
import { AuthService } from '../../auth/services/auth.service';
import { UserStatusService } from '../../auth/services/user-status.service';
import { VaultService } from '../../vault/services/vault.service';
import { AssetTemplate } from './assets-category';

export type Asset = AssetOutput & { beneficiaries: BeneficiaryOutput[] };

export type DecryptedAssetOutput = Omit<AssetOutput, keyof EncryptedFields> & PlainFields;
export type DecryptedAsset = Omit<Asset, keyof EncryptedFields> & PlainFields;

export interface PlainFields {
  password?: string;
  securityKey?: string;
  doubleAuth?: string;
}

export interface EncryptedFields {
  password?: Secret;
  securityKey?: Secret;
  doubleAuth?: Secret;
}

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  private assetsTemplates?: AssetTemplate[];
  private assets?: AssetOutput[];

  constructor(
    private assetAPIService: AssetAPIService,
    private authService: AuthService,
    private userStatusService: UserStatusService,
    private beneficiariesService: BeneficiariesService,
    private vaultService: VaultService,
  ) {
    this.authService.sessionUserObs.subscribe(user => {
      // Reset assets cache on user logout
      if (user === null) {
        this.assets = undefined;
      }
    });
  }

  async listAssetsTemplates(forceRefresh = false): Promise<AssetTemplate[]> {
    if (forceRefresh || !this.assetsTemplates) {
      const assetsTemplatesDto = await this.assetAPIService.listAssetsTemplates().toPromise();
      this.assetsTemplates = assetsTemplatesDto.map(assetTemplate => new AssetTemplate(assetTemplate.name, assetTemplate.category));
    }
    return this.assetsTemplates;
  }

  async listAssets(): Promise<Asset[]> {
    if (!this.assets) {
      this.assets = await this.assetAPIService.listAssets().toPromise();
    }
    const beneficiariesMap = await this.beneficiariesService.getBeneficiariesMap();
    return this.assets.map(asset => this.populateBeneficiaries(asset, beneficiariesMap));
  }

  async getAsset(assetId: string): Promise<Asset | undefined> {
    const assets = await this.listAssets();
    const asset = assets.find(asset => asset._id === assetId);
    if (asset) {
      return this.populateBeneficiaries(asset, await this.beneficiariesService.getBeneficiariesMap());
    } else {
      return undefined;
    }
  }

  async createAsset(asset: AssetInput): Promise<AssetOutput> {
    const newAsset = await this.assetAPIService.createAsset(asset).toPromise();
    if (this.assets) {
      this.assets.push(newAsset);
    }
    void this.userStatusService.refreshUserStatus();
    return newAsset;
  }

  async editAsset(assetId: string, asset: AssetInput): Promise<AssetOutput> {
    const updatedAsset = await this.assetAPIService.updateAsset(assetId, asset).toPromise();
    this.assets = this.assets?.map(asset => asset._id === assetId ? updatedAsset : asset);
    void this.userStatusService.refreshUserStatus();
    return updatedAsset;
  }

  async deleteAsset(assetId: string): Promise<void> {
    await this.assetAPIService.deleteAsset(assetId).toPromise();
    const index = this.assets?.findIndex(asset => asset._id === assetId)!;
    this.assets?.splice(index, 1);
    void this.userStatusService.refreshUserStatus();
  }

  async addBeneficiary(assetId: string, beneficiary: BeneficiaryOutput): Promise<void> {
    const asset = await this.getAsset(assetId);
    if (asset) {
      if (!asset.beneficiariesIds) {
        asset.beneficiariesIds = [beneficiary._id];
      } else {
        asset.beneficiariesIds.push(beneficiary._id);
      }
      await this.editAsset(assetId, asset);
      void this.userStatusService.refreshUserStatus();
    }
  }

  async encrypt(asset: DecryptedAsset): Promise<Asset>
  async encrypt(asset: DecryptedAssetOutput): Promise<AssetOutput>
  async encrypt(asset: any): Promise<any> {
    return {
      ...asset,
      securityKey: await this.vaultService.encrypt(asset.securityKey),
      doubleAuth: await this.vaultService.encrypt(asset.doubleAuth),
      password: await this.vaultService.encrypt(asset.password),
    };
  }

  async decrypt(asset: Asset): Promise<DecryptedAsset>
  async decrypt(asset: AssetOutput): Promise<DecryptedAssetOutput>
  async decrypt(asset: any): Promise<any> {
    return {
      ...asset,
      securityKey: !!asset.securityKey ? await this.vaultService.decrypt(asset.securityKey) : '',
      doubleAuth: !!asset.doubleAuth ? await this.vaultService.decrypt(asset.doubleAuth) : '',
      password: !!asset.password ? await this.vaultService.decrypt(asset.password) : '',
    };
  }

  private populateBeneficiaries(asset: AssetOutput, beneficiariesMap: Map<string, BeneficiaryOutput>): Asset {
    const beneficiaries: BeneficiaryOutput[] = asset.beneficiariesIds
      ?.map(beneficiaryId => beneficiariesMap.get(beneficiaryId))
      .filter(beneficiary => beneficiary !== undefined) as (BeneficiaryOutput[])
      || [];
    return {
      ...asset,
      beneficiaries,
    };
  }
}
