import { AuthRouterService } from '../../services/auth-router.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { errorToUserString, getAppErrorCode } from 'src/app/modules/core/components/error-dialog/error-msg-formator';
import { AuthRoutesNames } from '../../auth.routes.names';
import { AuthService } from '../../services/auth.service';
import { twoFactorsNotInitialized } from '../../services/two-factors';
import { VaultService } from 'src/app/modules/vault/services/vault.service';
import { Router } from '@angular/router';

const errorCodeMap = new Map<string, string>([
  ['badDeviceCode', 'Le code est incorrect'],
  ['deviceCodeExpired', 'Le code a expiré'],
]);

@Component({
  selector: 'app-verify-device',
  templateUrl: './verify-device.component.html',
  styleUrls: ['./verify-device.component.scss']
})
export class VerifyDeviceComponent implements OnInit {

  codeControl = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]);
  formGroup = new FormGroup({
    code: this.codeControl,
  });
  saveDevice = true;
  newDeviceCodeSent = false;

  signinLink = ['/', AuthRoutesNames.LOGIN];

  errorMessage?: string;

  constructor(
    private authService: AuthService,
    private authRouterService: AuthRouterService,
    private vaultService: VaultService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.authService.requireDeviceVerification()) {
      await this.router.navigate([AuthRoutesNames.AUTH_ROOT]);
    }
  }

  async onSubmit(): Promise<void> {
    if (this.formGroup.valid) {
      this.formGroup.disable();
      this.errorMessage = '';
      try {
        await this.authService.verifyDevice(this.formGroup.getRawValue(), this.saveDevice);
        await this.vaultService.attemptToretrieveUserKeysFromServer();
        await this.authRouterService.redirectAfterLogin();
      } catch (err) {
        if (
          err === twoFactorsNotInitialized || ['deviceCodeAlreadyConfirmed', 'deviceTokenUnknownOrExpired', 'badDeviceToken'].includes(getAppErrorCode(err))
        ) {
          await this.router.navigate([AuthRoutesNames.AUTH_ROOT]);
        }
        this.errorMessage = errorToUserString(err, errorCodeMap);
      } finally {
        this.formGroup.enable();
      }
    }
  }

  async sendNewDeviceCode(): Promise<void> {
    this.formGroup.disable();
    this.errorMessage = '';
    this.newDeviceCodeSent = true;
    try {
      await this.authService.sendNewDeviceCode();
    } catch (err) {
      console.log('sendNewDeviceCode error', err);
      this.newDeviceCodeSent = false;
      this.errorMessage = errorToUserString(err);
    } finally {
      this.formGroup.enable();
    }
  }
}
