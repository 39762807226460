import { VerifyDeviceComponent } from './pages/verify-device/verify-device.component';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';
import { ForgottenPasswordComponent } from './pages/forgotten-password/forgotten-password.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthRoutesNames } from './auth.routes.names';
import { RegisterRoutesNames } from '../register/register.routes.names';

export const AuthRoutes = [
  {
    path: AuthRoutesNames.LOGIN,
    component: LoginComponent,
  },
  {
    path: RegisterRoutesNames.ROOT,
    loadChildren: (): any => import('../../modules/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: AuthRoutesNames.CONFIRM_EMAIL,
    component: ConfirmEmailComponent,
  },
  {
    path: AuthRoutesNames.FORGOTTEN_PASSWORD,
    component: ForgottenPasswordComponent,
  },
  {
    path: `${AuthRoutesNames.RESET_PASSWORD}/:email/:token`,
    loadChildren: (): any => import('../../modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: AuthRoutesNames.VERIFY_DEVICE,
    component: VerifyDeviceComponent,
  },
  { path: '**', redirectTo: AuthRoutesNames.LOGIN, pathMatch: 'prefix' },
];
