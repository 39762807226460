import { Injectable } from "@angular/core";

const twoFactorsStorageKey = 'two-factors';

export const twoFactorsNotInitialized = new Error('twoFactorsNotInitialized');

export type TwoFactorsData = {
  email: string,
  deviceToken: string,
};

@Injectable({
  providedIn: 'root'
})
export class TwoFactors {

  initProcess(data: TwoFactorsData): void {
    sessionStorage.setItem(twoFactorsStorageKey, JSON.stringify(data));
  }

  isInProgress(): boolean {
    return !!sessionStorage.getItem(twoFactorsStorageKey);
  }

  getInProgressData(): TwoFactorsData {
    const data = sessionStorage.getItem(twoFactorsStorageKey);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        this.clearInProgressData();
      }
    }
    throw twoFactorsNotInitialized;
  }

  clearInProgressData(): void {
    sessionStorage.removeItem(twoFactorsStorageKey);
  }

  persistConfirmedDeviceToken(data: TwoFactorsData): void {
    localStorage.setItem(this.getDeviceTokenStorageId(data.email), data.deviceToken);
  }

  deleteConfirmedDeviceToken(email: string): void {
    sessionStorage.removeItem(twoFactorsStorageKey);
    localStorage.removeItem(this.getDeviceTokenStorageId(email));
  }

  getConfirmedDeviceToken(email: string): string | undefined {
    return localStorage.getItem(this.getDeviceTokenStorageId(email)) || undefined;
  }

  updateEmailAddress(previousEmail: string, newEmail: string): void {
    const deviceToken = localStorage.getItem(this.getDeviceTokenStorageId(previousEmail));
    if (deviceToken) {
      localStorage.setItem(this.getDeviceTokenStorageId(newEmail), deviceToken);
    }
  }

  private getDeviceTokenStorageId(email: string): string {
    return 'deviceToken_' + email;
  }

}