import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AuthService } from './modules/auth/services/auth.service';
import { KeysGenerationDialogComponent } from './modules/vault/components/keys-generation-dialog/keys-generation-dialog.component';
import { DialogArguments, MsgDialogComponent } from './modules/core/components/msg-dialog/msg-dialog.component';
import { VaultService } from './modules/vault/services/vault.service';
import { RestorationKeyDialogComponent } from './modules/vault/components/restoration-key-dialog/restoration-key-dialog.component';
import { AuthRouterService } from './modules/auth/services/auth-router.service';
import { icons } from './modules/core/components/icon/icon.component';

const refreshSessionDialogArg: DialogArguments = {
  title: 'Votre session va expirer',
  text: 'Votre session va bientôt expirer, veuillez confirmer pour prolonger votre session. ',
  confirmLabel: 'Prolonger ma session',
};

const sessionHasExpiredDialogArg: DialogArguments = {
  title: 'Votre session a expiré',
  text: 'Votre session a expiré',
  confirmLabel: 'Valider',
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  private refreshSessionDialog?: MatDialogRef<MsgDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private authRouterService: AuthRouterService,
    private vaultService: VaultService,
  ){
    this.addCustomSvgIcons();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.vaultService.keyGenInProgressObs.subscribe((inProgress) => {
        if (inProgress && !this.vaultService.isRestorationKeyGenerationInProgress()) {
          this.dialog.open(KeysGenerationDialogComponent, { disableClose: true });
        }
      })
    );
    this.subscriptions.add(
      this.vaultService.restorationRequiredObs.subscribe(() => {
        this.dialog.open(RestorationKeyDialogComponent, { disableClose: true });
      })
    );
    this.subscriptions.add(
      this.authService.sessionWillExpireObs().subscribe(async () => {
        this.refreshSessionDialog = this.dialog.open(MsgDialogComponent, {
          data: refreshSessionDialogArg,
        });
        this.refreshSessionDialog.afterClosed().subscribe(async () => {
          await this.authService.refreshSession();
        });
      })
    );
    this.subscriptions.add(
      this.authService.sessionHasExpiredObs().subscribe(async () => {
        if (this.refreshSessionDialog) {
          this.refreshSessionDialog.close();
        }
        this.dialog.open(MsgDialogComponent, {
          data: sessionHasExpiredDialogArg,
        });
        await this.authService.logout().catch(e => console.log('logout failed', e));
        await this.authRouterService.goToLoginPage();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private addCustomSvgIcons(): void {
    icons.forEach((value, key) => {
      if (value.endsWith('svg')) {
        this.addCustomSvgIcon(key, value);
      }
    });
  }

  private addCustomSvgIcon(refName: string, svgUrl: string): void {
    this.matIconRegistry.addSvgIcon(
      refName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(svgUrl)
    );
  }

}
