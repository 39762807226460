<h1 mat-dialog-title>Restauration de vos clés de chiffrement</h1>

<mat-dialog-content *ngIf="(guardianAskedIds.length === 0 || forceDisplayRestorationScreen) && !restorationSucceed">
  <p>
    Suite à la réinitialisation de votre mot de passe Solal qui servait à protéger l'accès à vos secret
    enregistrés sur la plateforme, une restauration de vos clés de chiffrement est nécessaire.
  </p>

  <div class="restorationWithRestorationKey" *ngIf="hasRestorationKey">
    La méthode la plus simple pour cela est de saisir une clé de restauration associée à votre compte :
    <form [formGroup]="formGroupRestorationKey" data-test=restore-form (ngSubmit)="restoreWithKey()" class="one-column-responsive panel">
      <mat-form-field appearance="outline" floatLabel="always" data-test=restoration-key>
        <mat-label>Clé de restauration</mat-label>
        <input matInput [formControl]="restorationKeyControl" placeholder="ex: ab12-34cd-efgh-i5j6-kl78" required
          data-test=restoration-key-input autocomplete="off" autofocus>
        <mat-error *ngIf="restorationKeyControl.errors?.required">Ce champ est requis</mat-error>
      </mat-form-field>

      <div class="form-error" *ngIf="errorMessage">{{errorMessage}}</div>

      <button type="submit" mat-flat-button color="accent" data-test="restore-secrets-button" [disabled]="formGroupRestorationKey.invalid">
        Restaurer mes secrets enregistrés
      </button>
    </form>
  </div>

  <div class="restorationWithGuardian" *ngIf="guardians.length > 0">
    Vous pouvez <span *ngIf="hasRestorationKey">également</span> soliciter un de vos gardiens pour récupérer l'accès à vos secrets :
    <form [formGroup]="formGroupGuardian" data-test=restore-form (ngSubmit)="restoreWithGuardian()" class="one-column-responsive panel">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Gardien</mat-label>
        <mat-select placeholder="Veuillez choisir un gardien" [formControl]="guardianChoiceControl" data-test=guardian-choice>
          <mat-option *ngFor="let guardian of guardians" [value]="guardian.guardianId">{{ guardian.firstName }} {{ guardian.lastName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="submit" mat-flat-button color="accent" data-test="restore-with-guardian-button" [disabled]="formGroupGuardian.invalid">
        Faire appel à ce gardien
      </button>
    </form>
  </div>

  <p class="reset-user-keys-container">
    <a [routerLink]="" (click)="generateNewUserkeysAndLooseAccessToAllSecrets()">
      Je n'ai pas de clé de restauration et mon gardien ne peut m'aider, j'ai conscience que je vais perdre mes secrets enregistrés
      mais je souhaite malgré tout réinitialiser mes clés de chiffrement.
    </a>
  </p>

</mat-dialog-content>



<mat-dialog-content *ngIf="guardianAskedIds.length > 0 && !forceDisplayRestorationScreen && !restorationSucceed">
  <p>
    Un email a été envoyé à votre gardien afin de permettre la restauration de la clé de chiffrement
    protégeant vos secrets.<br>
    Vous recevrez un email lorsque que la demande sera acceptée.<br>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="guardianAskedIds.length > 0 && !forceDisplayRestorationScreen && !restorationSucceed">
  <button mat-flat-button data-test="show-restoration-screen" (click)="forceDisplayRestorationScreen = true">
    Revenir sur l'écran permettant de saisir une clé de restauration.
  </button>
</mat-dialog-actions>



<mat-dialog-content *ngIf="restorationSucceed">
  <p>
    Vos clés de chiffrement ont été restaurées avec succès, vous avez à nouveau accès à vos secrets.<br>
    Suite à cette restauration, vos anciennes clés de restauration ne sont plus valide,
    nous vous invitons donc à généré une nouvelle clé de restauration au plus vite.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="restorationSucceed">
  <button mat-flat-button color="accent" data-test="close-restoration-dialog" mat-dialog-close>Fermer</button>
</mat-dialog-actions>


