import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH } from 'src/sdk/solal-core-api-sdk';
import { GlobalErrorHandler } from './modules/core/components/error-dialog/global-error-handler';
import { CoreModule } from './modules/core/core.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpAuthInterceptor } from './modules/auth/interceptors/http-auth.interceptor';
import { VaultModule } from './modules/vault/vault.module';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import {environment} from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,

    AuthModule,
    CoreModule,
    VaultModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500 } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
