import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthRoutesNames } from "../auth.routes.names";
import { AuthService } from "../services/auth.service";
import { RoleService } from "../services/role.service";

@Injectable({
  providedIn: 'root'
})
export class DefaultPageRedirectGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private roleService: RoleService,
    private router: Router,
  ) {
  }

  async canActivate(): Promise<boolean> {
    if (!await this.authService.isLoggedIn()) {
      await this.router.navigate([ AuthRoutesNames.AUTH_ROOT ]);
    } else {
      await this.router.navigate(await this.roleService.getDefaultPage());
    }
    return false;
  }

}