import { Component, Input, OnInit } from '@angular/core';
import { categoriesConfig } from 'src/app/modules/assets/services/assets-category';

export const icons = new Map<string, string>([
  [ 'antenna', 'assets/icons/antenna.svg' ],
  [ 'arrow-right', 'assets/icons/arrox-right.svg' ],
  [ 'astronaut', 'assets/icons/astronaut.svg' ],
  [ 'balloon', 'assets/icons/balloon.svg' ],
  [ 'bell', 'assets/icons/bell.svg' ],
  [ 'bin', 'assets/icons/bin.svg' ],
  [ 'cb', 'assets/icons/cb.svg' ],
  [ 'check', 'assets/icons/check.svg' ],
  [ 'chick', 'assets/icons/chick.svg' ],
  [ 'closed-umbrella', 'assets/icons/closed-umbrella.svg' ],
  [ 'cloud-lock', 'assets/icons/cloud-lock.svg' ],
  [ 'confetti-ball', 'assets/icons/confetti-ball.svg' ],
  [ 'cross', 'assets/icons/cross.svg' ],
  [ 'crossed-bell', 'assets/icons/crossed-bell.svg' ],
  [ 'crown', 'assets/icons/crown.svg' ],
  [ 'documents', 'assets/icons/documents.svg' ],
  [ 'download', 'assets/icons/download.svg' ],
  [ 'edit', 'assets/icons/edit.svg' ],
  [ 'email', 'assets/icons/email.svg' ],
  [ 'encrypted-file', 'assets/icons/encrypted-file.png' ],
  [ 'file-drawer', 'assets/icons/file-drawer.svg' ],
  [ 'folder', 'assets/icons/folder.png' ],
  [ 'guardian-lock', 'assets/icons/guardian-lock.png' ],
  [ 'guardians', 'assets/icons/guardians.svg' ],
  [ 'flame', 'assets/icons/flame.svg' ],
  [ 'handyman', 'assets/icons/handyman.svg' ],
  [ 'help', 'assets/icons/help.svg' ],
  [ 'home', 'assets/icons/home.svg' ],
  [ 'info', 'assets/icons/info.svg' ],
  [ 'legal-justice', 'assets/icons/legal-justice.svg' ],
  [ 'lock', 'assets/icons/lock.svg' ],
  [ 'lock-object', 'assets/icons/lock-object.svg' ],
  [ 'lock-shield', 'assets/icons/lock-shield.svg' ],
  [ 'logo', 'assets/icons/logo.svg' ],
  [ 'logo-with-text', 'assets/icons/logo-with-text.svg' ],
  [ 'notaire-de-france', 'assets/icons/notaire-de-france.svg' ],
  [ 'notary', 'assets/icons/notary.svg' ],
  [ 'pen', 'assets/icons/pen.svg' ],
  [ 'pen-circle', 'assets/icons/pen-circle.svg' ],
  [ 'picture', 'assets/icons/picture.svg' ],
  [ 'plus', 'assets/icons/plus.svg' ],
  [ 'profile', 'assets/icons/profile.svg' ],
  [ 'red-cross', 'assets/icons/red-cross.svg' ],
  [ 'restoration-key', 'assets/icons/restoration-key.png' ],
  [ 'rocket', 'assets/icons/rocket.svg' ],
  [ 'scroll', 'assets/icons/scroll.svg' ],
  [ 'search', 'assets/icons/search.svg' ],
  [ 'smiley-halo', 'assets/icons/smiley-halo.svg' ],
  [ 'solal-platform', 'assets/icons/solal-platform.png' ],
  [ 'soldat', 'assets/icons/soldat.svg' ],
  [ 'star', 'assets/icons/star.svg' ],
  [ 'stars', 'assets/icons/stars.svg' ],
  [ 'telescope', 'assets/icons/telescope.svg' ],
  [ 'testaments', 'assets/icons/testaments.svg' ],
  [ 'users', 'assets/icons/users.svg' ],
  [ 'warning-triangle', 'assets/icons/warning-triangle.svg' ],
  [ 'welcome-plane', 'assets/icons/welcome-plane.png' ],
  [ 'writing-hand', 'assets/icons/writing-hand.svg' ],

  [ 'file-image', 'assets/icons/file-formats/image.png' ],
  [ 'file-pdf', 'assets/icons/file-formats/pdf.png' ],
  [ 'file-text', 'assets/icons/file-formats/text.png' ],
  [ 'file-video', 'assets/icons/file-formats/video.png' ],
  [ 'file-unknown', 'assets/icons/file-formats/unknown.png' ],
  [ 'shield-cross', 'assets/icons/shield-cross.svg' ],
  [ 'law-office', 'assets/icons/law-office.svg' ],
  [ 'graph-data-diagram', 'assets/icons/graph-data-diagram.svg' ],
  [ 'question', 'assets/icons/question.svg' ],
  [ 'question-filled', 'assets/icons/question-filled.svg' ],
]);

for (const catConfig of categoriesConfig.values()) {
  icons.set(catConfig.icon, 'assets/icons/categories/' + catConfig.icon.substring(4) + '.png');
}

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: [ './icon.component.scss' ]
})
export class IconComponent implements OnInit {

  @Input() icon?: string;
  svg = false;
  src?: string;

  constructor() { }

  ngOnInit(): void {
    if (this.icon) {
      this.src = icons.get(this.icon);
      this.svg = !!this.src?.endsWith('svg');
    }
  }

}
