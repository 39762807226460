/**
 * coreAPI
 * API core
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AssetTemplate { 
    category: AssetTemplate.CategoryEnum;
    name: string;
}
export namespace AssetTemplate {
    export type CategoryEnum = 'passwordManager' | 'physicalDevice' | 'socialNetwork' | 'exchange' | 'mail' | 'streaming' | 'bank' | 'cloud' | 'shopping' | 'chat';
    export const CategoryEnum = {
        PasswordManager: 'passwordManager' as CategoryEnum,
        PhysicalDevice: 'physicalDevice' as CategoryEnum,
        SocialNetwork: 'socialNetwork' as CategoryEnum,
        Exchange: 'exchange' as CategoryEnum,
        Mail: 'mail' as CategoryEnum,
        Streaming: 'streaming' as CategoryEnum,
        Bank: 'bank' as CategoryEnum,
        Cloud: 'cloud' as CategoryEnum,
        Shopping: 'shopping' as CategoryEnum,
        Chat: 'chat' as CategoryEnum
    };
}


