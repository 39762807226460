import { AssetInput } from "src/sdk/solal-core-api-sdk";
import { AssetTemplate as AssetTemplateDto } from 'src/sdk/solal-core-api-sdk';

export type CategoryConfig = {
  displayName: string;
  icon: string;
};

export class AssetTemplate {
  public readonly catConfig: CategoryConfig;
  constructor(
    public readonly name: string,
    public readonly category: AssetTemplateDto.CategoryEnum,
  ) {
    const catConfig = categoriesConfig.get(category);
    if (!catConfig) {
      throw new Error('Unsupported category ' + category);
    }
    this.catConfig = catConfig;
  }
  toAsset(): AssetInput {
    return { name: this.name, category: this.category };
  }
}

const addCatConfig = (value: AssetTemplateDto.CategoryEnum, displayName: string, icon: string): void => {
  categoriesConfig.set(value, { displayName, icon });
};

export const categoriesConfig = new Map<AssetTemplateDto.CategoryEnum, CategoryConfig>();
addCatConfig(AssetTemplateDto.CategoryEnum.Bank, 'Banques', "cat-bank");
addCatConfig(AssetTemplateDto.CategoryEnum.SocialNetwork, 'Réseaux sociaux', "cat-social-network");
addCatConfig(AssetTemplateDto.CategoryEnum.Streaming, 'Streaming', "cat-streaming");
addCatConfig(AssetTemplateDto.CategoryEnum.Exchange, 'Exchange', "cat-exchange");
addCatConfig(AssetTemplateDto.CategoryEnum.PasswordManager, 'Mots de passe', "cat-password");
addCatConfig(AssetTemplateDto.CategoryEnum.Mail, 'Mails', "cat-mail");
addCatConfig(AssetTemplateDto.CategoryEnum.Chat, 'Messageries', "cat-chat");
addCatConfig(AssetTemplateDto.CategoryEnum.Cloud, 'Cloud', "cat-cloud");
addCatConfig(AssetTemplateDto.CategoryEnum.PhysicalDevice, 'Matériel', "cat-physical-device");
addCatConfig(AssetTemplateDto.CategoryEnum.Shopping, 'Online shopping', "cat-shopping");
