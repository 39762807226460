<div class="left-panel mat-typography">
  <div class="app-logo-container"*ngIf="!mobile">
    <app-logo></app-logo>
  </div>
  <div class="solaltechAdvantages" *ngIf="displaySolaltechAdvantages">
    <div class="solaltechAdvantages-title">
      Protégez vos actifs numériques, dès maintenant !
    </div>
    <div class="solaltechAdvantages-content">
      Les avantages de Solal Tech :
      <div class="solaltechAdvantages-table">
        <table>
          <tr>
            <td>
              <app-icon class="solaltechAdvantages-icon" icon="cloud-lock"></app-icon>
            </td>
            <td><span class="solaltechAdvantages-highlight">Sécurisez</span> vos actifs digitaux</td>
          </tr>
          <tr>
            <td>
              <app-icon class="solaltechAdvantages-icon" icon="shield-cross"></app-icon>
            </td>
            <td>Répertoriez-les en toute <span class="solaltechAdvantages-highlight">sécurité</span></td>
          </tr>
          <tr>
            <td>
              <app-icon class="solaltechAdvantages-icon" icon="law-office"></app-icon>
            </td>
            <td><span class="solaltechAdvantages-highlight">Bénéficiez</span> d’un accompagnement avec le notaire de votre choix</td>
          </tr>
          <tr>
            <td>
              <app-icon class="solaltechAdvantages-icon" icon="graph-data-diagram"></app-icon>
            </td>
            <td><span class="solaltechAdvantages-highlight">Centralisez</span> vos informations pour faciliter la transmission de vos comptes</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="citation" *ngIf="!mobile">
    <div class="citation-text" >
      <ng-content select="[citation-text]"></ng-content>
    </div>
    <div class="citation-author" *ngIf="!mobile">
      Flora Minaire, fondatrice de Solal
    </div>
  </div>
</div>
