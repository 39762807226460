
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GuardianState } from '@solal-tech/solal-common';
import { BeneficiaryOutput } from 'src/sdk/solal-core-api-sdk';
import { BeneficiariesService } from 'src/app/modules/beneficiaries/services/beneficiaries.service';
import { MsgDialogComponent } from 'src/app/modules/core/components/msg-dialog/msg-dialog.component';
import { GuardiansService } from 'src/app/modules/guardians/services/guardians.service';
import { ProfileRouteNames } from 'src/app/modules/profile/profile.routes.names';
import { InvalidRestorationKey, VaultService } from '../../../vault/services/vault.service';

@Component({
  selector: 'app-restoration-key-dialog',
  templateUrl: './restoration-key-dialog.component.html',
  styleUrls: ['./restoration-key-dialog.component.scss']
})
export class RestorationKeyDialogComponent implements OnInit {

  restorationKeyControl = new FormControl('', [Validators.required]);
  formGroupRestorationKey = new FormGroup({
    restorationKey: this.restorationKeyControl,
  });

  guardianChoiceControl = new FormControl('', [Validators.required]);
  formGroupGuardian = new FormGroup({
    guardianChoice: this.guardianChoiceControl,
  });

  errorMessage?: string;

  hasRestorationKey = false;

  guardians: BeneficiaryOutput[] = [];

  guardianAskedIds: string[] = [];

  forceDisplayRestorationScreen = false;

  restorationSucceed = false;

  constructor(
    public dialogRef: MatDialogRef<RestorationKeyDialogComponent>,
    private guardiansService: GuardiansService,
    private beneficiariesService: BeneficiariesService,
    private vaultService: VaultService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    const guardianRefs = (await this.guardiansService.listGuardians()).filter(g => g.restorExch);
    const restoreKeyRef = guardianRefs.find(g => g.restorExch?.encryptedVaultKey);
    if (restoreKeyRef) {
      await this.vaultService.setNewKeys({
        publicKey: restoreKeyRef.restorExch!.publicKey,
        privateKeyProtected: restoreKeyRef.restorExch!.privateKeyProtected,
        encryptedVaultKey: restoreKeyRef.restorExch!.encryptedVaultKey!,
      });
      this.restorationSucceed = true;
      await this.router.navigate([ ProfileRouteNames.ROOT, ProfileRouteNames.RESTORATION_KEYS ]);
    } else {
      this.guardianAskedIds = guardianRefs.map(g => g._id);
      const restorationKeys = await this.vaultService.getRestorationKeys();
      this.hasRestorationKey = restorationKeys.length > 0;
      this.guardians = (await this.beneficiariesService.listBeneficiaries()).filter(beneficiary => beneficiary.guardianState === GuardianState.keySet);
    }
  }

  async restoreWithKey(): Promise<void> {
    this.formGroupRestorationKey.disable();
    this.errorMessage = '';
    try {
      await this.vaultService.updatePvKeyProtection(this.restorationKeyControl.value);
      this.dialogRef.close();
    } catch (e) {
      if (e === InvalidRestorationKey) {
        this.errorMessage = 'La clé de restauration est invalide';
      } else {
        this.errorMessage = 'Erreur lors de la tentative de restauration';
      }
    } finally {
      this.formGroupRestorationKey.enable();
    }
  }

  async restoreWithGuardian(): Promise<void> {
    this.guardianAskedIds.push(this.guardianChoiceControl.value);
    const keys = await this.vaultService.generateUserAsymetricKeys();
    await this.guardiansService.initGuardianRestore(this.guardianChoiceControl.value, keys);
  }

  async generateNewUserkeysAndLooseAccessToAllSecrets(): Promise<void> {
    const deleteAssetDialog = this.dialog.open(MsgDialogComponent, {
      data: {
        title: 'Réinitialisation de vos clés de chiffrement',
        text: 'Attention, en effectuant cette action vous allez perdre tous vos secrets enregistrés sur la plateforme.<br>'
          + 'Êtes vous sûr de vouloir continuer ?',
        confirmLabel: 'Confirmer',
        cancelLabel: 'Annuler',
      }
    });
    if (await deleteAssetDialog.afterClosed().toPromise()) {
      this.dialogRef.close();
      await this.vaultService.generateNewUserkeysAndLooseAccessToAllSecrets();
    }
  }

}
