export const AdminRouteNames = {
  ROOT: 'admin',

  USERS: 'users',
  NOTARIES: 'notaries',
  NOTIFICATIONS: 'notifications',
  ASSETS_TEMPLATES: 'assets-templates',

  USER_STATUS: 'status',
  USER_INFO: 'info',
  USER_OFFICIAL_DOCS: 'official-docs',
  USER_PERMISSIONS: 'permissions',
};
