import { Router } from '@angular/router';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MsgDialogComponent } from 'src/app/modules/core/components/msg-dialog/msg-dialog.component';
import { VaultService } from '../../services/vault.service';

@Component({
  selector: 'app-keys-generation-dialog',
  templateUrl: './keys-generation-dialog.component.html',
  styleUrls: ['./keys-generation-dialog.component.scss']
})
export class KeysGenerationDialogComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  skipGenerationConfirmScreen = false;

  generationInProgress = true;

  keyVerificationStep = false;

  restorationKey?: string;

  restorationKeyGenerationInProgress = false;

  restorationKeyControl = new FormControl();

  guardianText = false;

  constructor(
    private vaultService: VaultService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { triggerGeneration: boolean },
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      this.vaultService.keyGenInProgressObs.subscribe((inProgress) => {
        this.guardianText = this.router.url.includes("guardians");
        this.generationInProgress = inProgress;
      })
    );
    if (this.data?.triggerGeneration) {
      this.skipGenerationConfirmScreen = true;
      await this.generateRestorationKey();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async generateRestorationKey(): Promise<void> {
    if (!this.restorationKeyGenerationInProgress) {
      this.restorationKeyGenerationInProgress = true;
      try {
        this.restorationKey = await this.vaultService.generateRestorationKey();
      } finally {
        this.restorationKeyGenerationInProgress = false;
      }
    }
  }

  async checkRestorationKey(): Promise<void> {
    this.restorationKeyControl.disable();
    try {
      const validRestorationKeyId = await this.vaultService.isRestorationKeyValid(this.restorationKeyControl.value);
      await this.dialog.open(MsgDialogComponent, {
        data : {
          title: 'Clé ' + (validRestorationKeyId ? 'valide' : 'invalide'),
          text: 'Votre clé est ' + (validRestorationKeyId ? 'valide.' : 'INVALIDE.'),
          confirmLabel: 'Ok',
        }
      }).afterClosed().toPromise();
      if (validRestorationKeyId) {
        this.dialog.closeAll();
      }
    } finally {
      this.restorationKeyControl.enable();
    }
  }

}
