import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog.component';

@Injectable()
export class ErrorDialogService {

  private errorMessages: string[] = [];

  private isDialogOpen = false;

  constructor(
    private dialog: MatDialog,
    private ngZone: NgZone,
  ) { }

  showErrorMessage(errorMsg: string): void {
    this.errorMessages.push(errorMsg);
    this.showNextError();
  }

  private openDialog(): void {
    this.isDialogOpen = true;
    this.dialog.open(ErrorDialogComponent, {
      data: this.errorMessages[0],
    }).afterClosed().subscribe(() => {
      this.errorMessages.shift();
      this.isDialogOpen = false;
      this.showNextError();
    });
  }

  private showNextError(): void {
    if (!this.isDialogOpen && this.errorMessages.length > 0) {
      this.ngZone.run(() => {
        this.openDialog();
      });
    }
  }
}