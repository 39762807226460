import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { errorToUserString, getAppErrorCode } from 'src/app/modules/core/components/error-dialog/error-msg-formator';
import { RegisterRoutesNames } from 'src/app/modules/register/register.routes.names';
import { VaultService } from 'src/app/modules/vault/services/vault.service';
import { AuthRoutesNames } from '../../auth.routes.names';
import { EMAIL_VALIDATORS_REQUIRED } from '../../components/email-field/email-field.component';
import { AuthRouterService } from '../../services/auth-router.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  emailControl = new FormControl('', EMAIL_VALIDATORS_REQUIRED);
  passwordControl = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(255)]);

  formGroup = new FormGroup({
    email: this.emailControl,
    password: this.passwordControl,
  });

  signupLink = [ '../', RegisterRoutesNames.ROOT ];
  forgottenPasswordLink = [ '../', AuthRoutesNames.FORGOTTEN_PASSWORD ];

  errorMessage?: string;

  constructor(
    private authService: AuthService,
    private authRouterService: AuthRouterService,
    private vaultService: VaultService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const email = this.route.snapshot.paramMap.get('email') || this.authService.getPersistedEmail();
    if (email) {
      this.emailControl.setValue(email);
    }
  }

  async onSubmit(): Promise<void> {
    if (this.formGroup.valid) {
      this.formGroup.disable();
      try {
        const user = await this.authService.login(this.formGroup.getRawValue());
        await this.vaultService.setUserMasterKey({ ...user, password: this.passwordControl.value });
        await this.authRouterService.redirectAfterLogin();
      } catch (err) {
        if (getAppErrorCode(err) === 'emailNotConfirmed') {
          await this.router.navigate([ AuthRoutesNames.AUTH_ROOT, AuthRoutesNames.CONFIRM_EMAIL, { email: this.emailControl.value } ]);
        }
        this.errorMessage = errorToUserString(err);
      } finally {
        this.formGroup.enable();
      }
    }
  }

}
