import { Injectable } from "@angular/core";
import { ConfigAPIService } from "src/sdk/solal-core-api-sdk/api/configAPI.service";
import { Config } from "src/sdk/solal-core-api-sdk/model/config";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config?: Config;

  constructor(
    private configApi: ConfigAPIService,
  ) {}

  async getConfig(): Promise<Config> {
    if (!this.config) {
      this.config = await this.configApi.getConfig().toPromise();
    }
    return this.config;
  }

  async reportError(err: object): Promise<void> {
    await this.configApi.reportError(err).toPromise();
  }

}
