import { hasPermissions, PermissionFlag } from "@solal-tech/solal-common";

export type SessionUser = {
  _id: string,
  email: string,
  permissions: number,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  postalAddress?: string,
  birthdate?: string,
  birthplace?: string,
  birthCountry?: string,
};

export function hasPermission(user: SessionUser|undefined|null, permissionFlag: PermissionFlag): boolean {
  return !!user && hasPermissions(user.permissions, permissionFlag);
}
