import { NgModule } from "@angular/core";
import { KeysGenerationDialogComponent } from "./components/keys-generation-dialog/keys-generation-dialog.component";
import { RestorationKeyDialogComponent } from "./components/restoration-key-dialog/restoration-key-dialog.component";
import { VaultService } from "./services/vault.service";
import { CoreModule } from "../core/core.module";

@NgModule({
  declarations: [
    // components
    KeysGenerationDialogComponent,
    RestorationKeyDialogComponent,
  ],
  imports: [
    CoreModule,
  ],
  providers: [
    // services
    VaultService,
  ],
  exports: [
    // components
    KeysGenerationDialogComponent,
    RestorationKeyDialogComponent,
  ],
})
export class VaultModule { }
