<app-auth-page>

  <form [formGroup]="formGroup" data-test=verify-device-form (ngSubmit)="onSubmit()" class=one-column-responsive>

    <mat-form-field appearance="outline" floatLabel="always" data-test=verify-device-code>
      <mat-label>Code d'authentification</mat-label>
      <input matInput [formControl]="codeControl" placeholder="ex: abc01xyz" required data-test=verify-device-code-input
        autocomplete="off" id="code">
      <mat-error *ngIf="codeControl.errors?.required">Ce champ est requis</mat-error>
      <mat-error *ngIf="codeControl.errors?.minlength || codeControl.errors?.maxlength">
        Le code d'authentification doit faire 8 caractères
      </mat-error>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="saveDevice" [ngModelOptions]="{standalone: true}" color="primary">
      Ne plus me demander sur cet appareil.
    </mat-checkbox>

    <div class="form-error" *ngIf="errorMessage">{{errorMessage}}</div>

    <div class="form-message" *ngIf="!newDeviceCodeSent">Un code d'authentification a été envoyé sur votre adresse email de contact</div>
    <div class="form-message" *ngIf="newDeviceCodeSent">Un nouveau code d'authentification vous a été envoyé sur votre adresse email de contact</div>
    <div class="form-link" *ngIf="!newDeviceCodeSent">
      <a [routerLink]="" data-test=send-new-code-link (click)=sendNewDeviceCode()>Envoyer un nouveau code</a>
    </div>

    <button type="submit" mat-flat-button color="accent" class="form-action" data-test="verify-device-button"
      [disabled]="formGroup.invalid">Continuer</button>

  </form>

</app-auth-page>
