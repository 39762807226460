import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { AddressComponent } from './components/address/address.component';
import { ErrorDialogComponent } from "./components/error-dialog/error-dialog.component";
import { ErrorDialogService } from "./components/error-dialog/error-dialog.service";
import { MsgDialogComponent } from "./components/msg-dialog/msg-dialog.component";
import { InfoStepComponent } from './components/info-step/info-step.component';
import { LogoComponent } from './components/logo/logo.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SubMenuComponent } from "./components/sub-menu/sub-menu.component";
import { PageWithMainMenuComponent } from './components/page-with-main-menu/page-with-main-menu.component';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { PageHeaderActionComponent } from './components/page-header-action/page-header-action.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SubMenuLinkComponent } from "./components/sub-menu-link/sub-menu-link.component";
import { ByteSizePipe } from "./pipes/byte-size.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { IconComponent } from './components/icon/icon.component';
import { MatSelectModule } from "@angular/material/select";
import { LowerCaseDirective } from "./directives/lowercase";
import { LeftPanelCitationComponent } from './components/left-panel-citation/left-panel-citation.component';
import { LeftPanelGuardianInvitationComponent } from "./components/left-panel-guardian-invitation/left-panel-guardian-invitation.component";
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { CityComponent } from "./components/city/city.component";
import { CountryComponent } from "./components/country/country.component";
import { RoleSelectorComponent } from './components/role-selector/role-selector.component';
import { HamburgerHeaderComponent } from './components/hamburger-header/hamburger-header.component';
import { PersonInfoComponent } from './components/person-info/person-info.component';
import { TestatorStatusComponent } from './components/testator-status/testator-status.component';
import { AvatarsComponent } from './components/avatars/avatars.component';
import { ProgressCircleComponent } from "./components/progress-circle/progress-circle.component";
import { MsgBoxComponent } from './components/msg-box/msg-box.component';
import { UpperCaseDirective } from "./directives/uppercase";
import { CapitalizeDirective } from "./directives/capitalize";

@NgModule({
  declarations: [
    // components
    AddressComponent,
    AvatarComponent,
    AvatarsComponent,
    DatePickerComponent,
    ErrorDialogComponent,
    MsgDialogComponent,
    IconComponent,
    InfoStepComponent,
    LogoComponent,
    MainMenuComponent,
    SubMenuComponent,
    SubMenuLinkComponent,
    PageWithMainMenuComponent,
    PageHeaderActionComponent,
    ProgressCircleComponent,
    LeftPanelCitationComponent,
    LeftPanelGuardianInvitationComponent,
    CityComponent,
    CountryComponent,
    PersonInfoComponent,
    TestatorStatusComponent,
    RoleSelectorComponent,
    HamburgerHeaderComponent,
    MsgBoxComponent,
    // pipes
    ByteSizePipe,
    // directives
    CapitalizeDirective,
    LowerCaseDirective,
    UpperCaseDirective,
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    // services
    ErrorDialogService,
    // pipes
    ByteSizePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ],
  exports: [
    // shared modules
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule,

    ReactiveFormsModule,
    RouterModule,
    // components
    AddressComponent,
    AvatarComponent,
    AvatarsComponent,
    DatePickerComponent,
    ErrorDialogComponent,
    SubMenuComponent,
    SubMenuLinkComponent,
    HamburgerHeaderComponent,
    MsgDialogComponent,
    IconComponent,
    InfoStepComponent,
    LogoComponent,
    MsgBoxComponent,
    PageHeaderActionComponent,
    PageWithMainMenuComponent,
    ProgressCircleComponent,
    LeftPanelCitationComponent,
    LeftPanelGuardianInvitationComponent,
    CityComponent,
    CountryComponent,
    PersonInfoComponent,
    TestatorStatusComponent,
    // pipes
    ByteSizePipe,
    // directives
    CapitalizeDirective,
    LowerCaseDirective,
    UpperCaseDirective,
  ],
})
export class CoreModule { }
