import { Component, Input } from '@angular/core';

export enum MsgType {
  info = 'info',
  warning = 'warning',
}

@Component({
  selector: 'app-msg-box',
  templateUrl: './msg-box.component.html',
  styleUrls: ['./msg-box.component.scss']
})
export class MsgBoxComponent{

  types = MsgType;

  @Input() msgType: keyof typeof MsgType = MsgType.info;

}
