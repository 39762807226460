import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "../../services/config.service";
import { MsgDialogComponent } from "../msg-dialog/msg-dialog.component";
import { ErrorDialogService } from "./error-dialog.service";
import { fallbackErrorMsg } from "./error-msg";
import { errorToUserString } from "./error-msg-formator";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private dialog: MatDialog,
    private errorDialogService: ErrorDialogService,
    private configService: ConfigService,
    private ngZone: NgZone,
  ) {}

  async handleError(error: any): Promise<void> {
    const errorMsg = errorToUserString(error.rejection || error, undefined, '');
    if (errorMsg || !error.message) {
      this.errorDialogService.showErrorMessage(errorMsg || fallbackErrorMsg);
    } else {
      await this.ngZone.run(async () => {
        const sendReport = await this.dialog.open(MsgDialogComponent, {
          data: {
            text: "Désolé, un problème technique est survenu, nous vous invitons à réessayer ultérieurement.<br><br>"
              + "Si vous le souhaitez, vous pouvez nous faire parvenir un rapport d'erreur pour nous aider à résoudre le problème dans les plus brefs délais.",
            confirmLabel: "Envoyer",
            cancelLabel: 'Ne pas envoyer',
          }
        }).afterClosed().toPromise();
        if (sendReport) {
          void this.sendErrorReport(error);
        }
      });
    }
  }

  async sendErrorReport(error: any): Promise<void> {
    const errorReport = { href: window.location.href, message: error.message };
    const confirm = await this.dialog.open(MsgDialogComponent, {
      data: {
        text: "Voici le contenu du rapport d'erreur qui sera envoyé à l'équipe technique de SOLAL TECH :<br>"
          + `<div class="error-report-content">${JSON.stringify(errorReport, null, 2)}</div>`,
        confirmLabel: "Confirmer l'envoi",
        cancelLabel: 'Ne pas envoyer',
      }
    }).afterClosed().toPromise();
    if (confirm) {
      try {
        await this.configService.reportError(errorReport);
        this.dialog.open(MsgDialogComponent, {data: {
          text: "Le rapport a été envoyé, merci d'avoir signalé le problème à notre équipe technique. Nous allons le prendre en charge au plus vite.",
          confirmLabel: "Fermer",
        }});
      } catch (err) {
        console.log('error sending error report', err);
        this.dialog.open(MsgDialogComponent, {data: {
          text: "Le rapport n'a pu être envoyé, merci de vérifier votre connexion réseau ou de réessayer ultérieurement.",
          confirmLabel: "Fermer",
        }});
      }
    }
  }

}
