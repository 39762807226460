import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { errorToUserString } from 'src/app/modules/core/components/error-dialog/error-msg-formator';
import { AuthRoutesNames } from '../../auth.routes.names';
import { EMAIL_VALIDATORS_REQUIRED } from '../../components/email-field/email-field.component';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent {

  signinLink = [ '../', AuthRoutesNames.LOGIN ];

  emailControl = new FormControl('', EMAIL_VALIDATORS_REQUIRED);

  formGroup = new FormGroup({
    email: this.emailControl,
  });

  errorMessage?: string;

  emailSent = false;

  constructor(
    private authService: AuthService,
  ) { }

  async onSubmit(): Promise<void> {
    if (this.formGroup.valid) {
      this.formGroup.disable();
      try {
        await this.authService.forgottenPassword(this.formGroup.getRawValue());
        this.emailSent = true;
      } catch (err) {
        this.errorMessage = errorToUserString(err);
      } finally {
        this.formGroup.enable();
      }
    }
  }

}
