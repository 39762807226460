import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[lowercase]',
})
export class LowerCaseDirective {

  @HostListener('input', ['$event.target'])
  onInput(input: any): void {
    try {
      if (input.value) {
        const newValue = input.value.toLowerCase();
        if (newValue !== input.value) {
          const start = input.selectionStart;
          const end = input.selectionEnd;
          input.value = newValue;
          input.setSelectionRange(start, end);
          input.dispatchEvent(new Event('input'));
        }
      }
    } catch (e) {
      console.log('[WARN] unable to format ' + input.value, e);
    }
  }

}
