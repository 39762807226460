<app-left-panel-citation *ngIf="!mobileOrTablet && !invitation && testator" [displaySolaltechAdvantages]="testator">
  <div citation-text>
    “Solal Tech est votre solution technique pour répertorier, sécuriser et transmettre vos informations essentielles et préserver ainsi votre patrimoine numérique.”
  </div>
</app-left-panel-citation>
<app-left-panel-citation *ngIf="!mobileOrTablet && !invitation && !testator" class="no-star v-center">
  <div citation-text>
    Bienvenue sur SOLAL TECH,<br><br>
    SOLAL est votre solution technique sécurisée pour accompagner vos clients sur la transmission de leurs biens et actifs numériques.
    Accompagnez-les et apportez leur votre expertise à chaque étape.
  </div>
</app-left-panel-citation>
<app-left-panel-guardian-invitation *ngIf="!mobileOrTablet && invitation" [invitation]="invitation"></app-left-panel-guardian-invitation>
<div class="auth-page-content-container">
  <div class="mobile-header" *ngIf="mobileOrTablet">
    <app-logo></app-logo>
    <div>
      <div *ngIf="testator" class="about-content">
        <app-left-panel-citation [displaySolaltechAdvantages] = true [mobile]="mobileOrTablet"></app-left-panel-citation>
      </div>
    </div>
  </div>
  <div class="auth-page-header">
    <div class="page-title"><ng-content select="[page-title]"></ng-content></div>
  </div>
  <div class="auth-page-content">
    <ng-content></ng-content>
  </div>
</div>
