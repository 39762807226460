import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRoutes } from './modules/auth/auth.routes';
import { AuthRoutesNames } from './modules/auth/auth.routes.names';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { UnauthGuard } from './modules/auth/guards/unauth.guard';
import { BeneficiariesRouteNames } from './modules/beneficiaries/beneficiaries.routes.names';
import { AssetsRouteNames } from './modules/assets/assets.routes.names';
import { GuardiansRouteNames } from 'src/app/modules/guardians/guardians.routes.names';
import { GuardianRouteNames } from './modules/guarded-users/guarded-users.routes.names';
import { NotaryRouteNames } from './modules/notary/notary.routes.names';
import { TestamentsRouteNames } from './modules/testaments/testaments.routes.names';
import { ProfileRouteNames } from './modules/profile/profile.routes.names';
import { PaymentsRouteNames } from './modules/payments/payments.routes.names';
import { DefaultPageRedirectGuard } from './modules/auth/guards/default-page-redirect.guard';
import { TestatorGuard } from './modules/auth/guards/testator.guard';
import { NotaryGuard } from './modules/auth/guards/notary.guard';
import { GuardianGuard } from './modules/auth/guards/guardian.guard';
import { AdminRouteNames } from './modules/admin/admin.routes.names';
import { AdminGuard } from './modules/auth/guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [DefaultPageRedirectGuard],
    children: [],
  },
  { path: '', redirectTo: AssetsRouteNames.ROOT, pathMatch: 'full' },
  { path: AuthRoutesNames.AUTH_ROOT, children: AuthRoutes, canActivate: [UnauthGuard] },
  {
    path: AssetsRouteNames.ROOT, canActivate: [ AuthGuard, TestatorGuard ],
    loadChildren: (): any => import('./modules/assets/assets.module').then(m => m.AssetsModule),
  },
  {
    path: BeneficiariesRouteNames.ROOT, canActivate: [ AuthGuard, TestatorGuard ],
    loadChildren: (): any => import('./modules/beneficiaries/beneficiaries.module').then(m => m.BeneficiariesModule),
  },
  {
    path: GuardiansRouteNames.ROOT,
    loadChildren: (): any => import('./modules/guardians/guardians.module').then(m => m.GuardiansModule),
  },
  {
    path: ProfileRouteNames.ROOT, canActivate: [AuthGuard],
    loadChildren: (): any => import('./modules/profile/profile-routing.module').then(m => m.ProfileRoutingModule),
  },
  {
    path: PaymentsRouteNames.ROOT, canActivate: [AuthGuard],
    loadChildren: (): any => import('./modules/payments/payments.module').then(m => m.PaymentsModule),
  },
  {
    path: TestamentsRouteNames.ROOT, canActivate: [ AuthGuard, TestatorGuard ],
    loadChildren: (): any => import('./modules/testaments/testaments.module').then(m => m.TestamentsModule),
  },
  {
    path: GuardianRouteNames.ROOT, canActivate: [ AuthGuard, GuardianGuard ],
    loadChildren: (): any => import('./modules/guarded-users/guarded-users.module').then(m => m.GuardedUsersModule),
  },
  {
    path: NotaryRouteNames.ROOT, canActivate: [ AuthGuard, NotaryGuard ],
    loadChildren: (): any => import('./modules/notary/notary-routing.module').then(m => m.NotaryRoutingModule),
  },
  {
    path: AdminRouteNames.ROOT, canActivate: [ AuthGuard, AdminGuard ],
    loadChildren: (): any => import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
