import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss']
})
export class PasswordFieldComponent {

  passwordHidden = true;

  @Input() passwordControl!: FormControl;
  @Input() label = 'Mot de passe';
  @Input() placeholder = 'ex : m0n-mÔẗ-2-p@ś$e';
  @Input() autocomplete: 'current-password'|'new-password'|'off' = 'current-password';
  @Input() required = true;
  @Input() readonly = false;

  constructor() { }

  changePasswordVisibility(): void {
    this.passwordHidden = !this.passwordHidden;
  }

  getPasswordIcon(): string {
    return this.passwordHidden ? 'visibility_off' : 'visibility';
  }

  getPasswordType(): string {
    return this.passwordHidden ? 'password' : 'text';
  }

}
