import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionFlag } from '@solal-tech/solal-common';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PaymentsRouteNames } from '../../payments/payments.routes.names';
import { ProfileRouteNames } from '../../profile/profile.routes.names';
import { AuthRouterService } from '../services/auth-router.service';
import { Role, RoleService } from '../services/role.service';

@Injectable({
  providedIn: 'root'
})
export class TestatorGuard implements CanActivate {

  constructor(
    private roleService: RoleService,
    private authService: AuthService,
    private authRouterService: AuthRouterService,
    private router: Router,
  ) { }

  async canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!await this.authService.isLoggedIn()) {
      await this.authRouterService.goToLoginPage(state.url);
      return false;
    } else if (await this.authService.isAccountDeleted()) {
      await this.router.navigate([ProfileRouteNames.ROOT, ProfileRouteNames.ACCOUNT_DELETED]);
      return false;
    } else if (!(await this.authService.hasPermission(PermissionFlag.TestatorSpaceAccess))) {
      await this.router.navigate([PaymentsRouteNames.ROOT, PaymentsRouteNames.FREE_TRIAL]);
      return false;
    }
    this.roleService.changeRole(Role.testator);
    return true;
  }

}
