import { bufferArrayToStr, strToArrayBuffer } from "./text-encode";

const solalPrivateKeySaltPrefix = 'solalPK-Cc6Ey3QRzt2W48#';
const solalRestoreKeySaltPrefix = 'solalRestoreKey-Nzjas5LNaQybxz#';
const solalPasswordHashSaltPrefix = 'solalHash-myuQBV8on7Se4D#';
const solalIVSalt = 'solalIv-iWwbjyMGdi6pwV';

export async function hashUserPassword(user: { email: string, password: string }): Promise<string> {
  const userKeyMaterial = await getKeyMaterial(user.password);
  const salt = strToArrayBuffer(solalPasswordHashSaltPrefix + user.email);
  const hash = await window.crypto.subtle.deriveBits(
    {
      "name": "PBKDF2",
      salt,
      "iterations": 101_000,
      "hash": "SHA-256",
    },
    userKeyMaterial,
    256,
  );
  return arrayBufferToBase64(hash);
}

export async function deriveUserMasterKey(user: { _id: string, password: string }): Promise<CryptoKey> {
  const userKeyMaterial = await getKeyMaterial(user.password);
  const salt = strToArrayBuffer(solalPrivateKeySaltPrefix + user._id);
  return await window.crypto.subtle.deriveKey(
    {
      "name": "PBKDF2",
      salt,
      "iterations": 100_010,
      "hash": "SHA-256",
    },
    userKeyMaterial,
    { "name": "AES-GCM", "length": 256},
    true,
    ["wrapKey", "unwrapKey"],
  );
}

export async function deriveUserRestorationKey(user: { userId: string, restorationKey: string }): Promise<CryptoKey> {
  const userKeyMaterial = await getKeyMaterial(user.restorationKey);
  const salt = strToArrayBuffer(solalRestoreKeySaltPrefix + user.userId);
  return await window.crypto.subtle.deriveKey(
    {
      "name": "PBKDF2",
      salt,
      "iterations": 100_010,
      "hash": "SHA-256",
    },
    userKeyMaterial,
    { "name": "AES-GCM", "length": 256},
    true,
    ["wrapKey", "unwrapKey"],
  );
}

export async function exportUserMasterKey(key: CryptoKey): Promise<string> {
  return arrayBufferToBase64(await window.crypto.subtle.exportKey('raw', key));
}

export async function importUserMasterKey(keyToImport: string): Promise<CryptoKey> {
  const keyBuffer = base64ToArrayBuffer(keyToImport);
  return await window.crypto.subtle.importKey('raw', keyBuffer, "AES-GCM", true, ["wrapKey", "unwrapKey"]);
}

export async function genRSAKeyPair(): Promise<Required<CryptoKeyPair>> {
  return await window.crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 4_096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["wrapKey", "unwrapKey"],
  ) as Required<CryptoKeyPair>;
}

export async function exportPublicKey(publicKey: CryptoKey): Promise<string> {
  return arrayBufferToBase64(await window.crypto.subtle.exportKey("spki", publicKey));
}

export async function importPublicKey(exportedKey: string): Promise<CryptoKey> {
  const importParam: RsaHashedImportParams = {
    name: "RSA-OAEP",
    hash: "SHA-256",
  };
  return await window.crypto.subtle.importKey(
    "spki",
    base64ToArrayBuffer(exportedKey),
    importParam,
    true,
    ["wrapKey"],
  );
}

export async function wrapRSAKey(keyToWrap: CryptoKey, wrappingKey: CryptoKey, iv: ArrayBuffer): Promise<string> {
  const wrapped = await window.crypto.subtle.wrapKey(
    "pkcs8",
    keyToWrap,
    wrappingKey,
    {
      name: "AES-GCM",
      iv,
    },
  );
  return arrayBufferToBase64(wrapped);
}

export async function unwrapRSAKey(wrappedKey: string, unwrappingKey: CryptoKey, iv: ArrayBuffer): Promise<CryptoKey> {
  const wrappedKeyBuffer = base64ToArrayBuffer(wrappedKey);
  return await window.crypto.subtle.unwrapKey(
    "pkcs8",
    wrappedKeyBuffer,
    unwrappingKey,
    {
      name: "AES-GCM",
      iv,
    },
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["unwrapKey"],
  );
}

export async function genAESKey(): Promise<CryptoKey> {
  return window.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"],
  );
}

export async function wrapAESKey(keyToWrap: CryptoKey, wrappingKey: CryptoKey): Promise<string> {
  const wrapped = await window.crypto.subtle.wrapKey(
    "raw",
    keyToWrap,
    wrappingKey,
    "RSA-OAEP",
  );
  return arrayBufferToBase64(wrapped);
}

export async function unwrapAESKey(wrappedKey: string, unwrappingKey: CryptoKey): Promise<CryptoKey> {
  const wrappedKeyBuffer = base64ToArrayBuffer(wrappedKey);
  const key = await window.crypto.subtle.unwrapKey(
    "raw",
    wrappedKeyBuffer,
    unwrappingKey,
    "RSA-OAEP",
    "AES-GCM",
    true,
    ["encrypt", "decrypt"],
  );
  return key;
}

export async function encryptMessage(key: CryptoKey, message: string, iv: ArrayBuffer): Promise<string> {
  const encryptedMessage = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
    },
    key,
    strToArrayBuffer(message),
  );
  return arrayBufferToBase64(encryptedMessage);
}

export async function decryptMessage(key: CryptoKey, encryptedMessage: string, iv: ArrayBuffer): Promise<string> {
  const encryptedMessageBuffer = base64ToArrayBuffer(encryptedMessage);
  const messageBuffer = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv,
    },
    key,
    encryptedMessageBuffer,
  );
  return bufferArrayToStr(messageBuffer);
}

export async function deriveInitialVectorFromId(id: string): Promise<ArrayBuffer> {
  const keyMaterial = await getKeyMaterial(id);
  const salt = strToArrayBuffer(solalIVSalt);
  return await window.crypto.subtle.deriveBits(
    {
      "name": "PBKDF2",
      salt,
      "iterations": 10,
      "hash": "SHA-256",
    },
    keyMaterial,
    128,
  );
}

export function generateInitialVector(): ArrayBuffer {
  return window.crypto.getRandomValues(new Uint8Array(16));
}

async function getKeyMaterial(password: string): Promise<CryptoKey> {
  return await window.crypto.subtle.importKey(
    "raw",
    strToArrayBuffer(password),
    "PBKDF2",
    false,
    ["deriveBits", "deriveKey"],
  );
}

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
