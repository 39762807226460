<div *ngIf="!keyVerificationStep && !restorationKey && !skipGenerationConfirmScreen">
  <mat-dialog-content>
    <div class="title-icon">
      <app-icon icon="encrypted-file"></app-icon><div class="title">Génération des clés de chiffrement</div>
    </div>
    <div *ngIf="!guardianText">
      <p>
        Des clés de chiffrement sont générées de manière automatique et sont liées à votre compte.
        Elles sont invisibles pour vous, mais elles sont incontournables.
        En effet, elles <strong>protègent efficacement vos secrets, dont les mots de passe des biens et actifs numériques</strong> que vous répertoriez sur la plateforme SOLAL TECH.
      </p>
      <p>
        Pour optimiser la sécurité et garantir que la plateforme SOLAL TECH ne puisse accéder à vos informations de quelque manière que ce soit, les clés de chiffrement sont générées <strong>directement dans votre navigateur</strong>.
      </p>
    </div>
    <div *ngIf="guardianText">
      <p>
        Des clés sont générées de manière automatique et sont attachées à votre compte, mais invisibles pour vous.
        Elles permettent de <strong>lier votre compte avec celui de l’utilisateur vous ayant demandé d'être son gardien</strong>.
      </p>
      <p>
        Pour optimiser la sécurité et garantir que <strong>la plateforme Solal Tech ne pourra accéder à vos informations
        de quelque manière que ce soit</strong>, les clés de chiffrement sont générées dans votre navigateur.
      </p>
      <p>
        Deux opérations pourront alors vous être demandées:
      </p>
      <ul>
        <li>
          En cas de perte par l’utilisateur de ses identifiants pour se connecter à son compte Solal, ce dernier pourra
          vous solliciter comme personne de confiance, pour lui permettre de générer de nouveaux identifiants.
        </li>
        <li>
          En cas de décès de l’utilisateur, et après validation par le Notaire partenaire choisi par l’utilisateur, vous
          aurez accès aux volontés numériques afin d'exécuter ces dernières. Vous serez accompagné pour cela, par la
          plateforme et les équipes de Solal Tech.
        </li>
      </ul>
    </div>

    <div class="title-icon restoration-key-section">
      <app-icon icon="restoration-key"></app-icon><div class="title">Génération de la clé de restauration</div>
    </div>
    <p>
      Pour finaliser votre inscription, SOLAL TECH vous incite très fortement à générer une clé de restauration.
      Cette clé vous permettra de générer un nouveau mot de passe pour votre compte SOLAL TECH, si par mésaventure vous oubliez votre mot de passe.
    </p>
    <p>
      Nous vous conseillons de recopier cette clé de restauration sur format papier et de l'entreposer dans un endroit sécurisé afin de pouvoir retrouver l'accès à votre compte en cas de besoin.
    </p>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button color="accent" mat-flat-button data-test="close-key-generations-dialog-button" *ngIf="restorationKey" (click)="keyVerificationStep=true">
      J'ai sauvegardé ma clé de restauration
    </button>
    <button mat-flat-button color="accent" data-test="generate-restoration-key-button"
      [disabled]="restorationKeyGenerationInProgress" (click)="generateRestorationKey()">
      Générer une clé de restauration
    </button>
    <button mat-flat-button color="transparent" data-test="skip-generate-restoration-key-button" [disabled]="generationInProgress" mat-dialog-close>Continuer sans clé de restauration</button>
  </mat-dialog-actions>
</div>

<div *ngIf="skipGenerationConfirmScreen && restorationKeyGenerationInProgress">
  Génération en cours...
</div>

<div *ngIf="!keyVerificationStep && restorationKey">
  <div class="title-icon">
    <app-icon icon="restoration-key"></app-icon><div class="title">Clé de restauration</div>
  </div>
  <mat-dialog-content>
    <p>
      Découvrez ci-dessous votre clé de restauration. Elle vous permet de retrouver vos identifiants en cas d'oubli du mot de passe de votre compte SOLAL TECH :
    </p>
    <pre class="restoration-key" data-test=restoration-key-value>{{restorationKey}}</pre>
    <p>
      Nous vous invitons à la recopier sur format papier et à l'entreposer dans un endroit sécurisé.
      Cette clé n'étant pas connue de l'équipe SOLAL TECH, nous ne serons pas en possibilité de vous la communiquer à nouveau.
      Cependant, vous pourrez générer de nouvelles clés tant que vous possédez le mot de passe d'accès à votre compte SOLAL TECH.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button color="accent" mat-flat-button data-test="close-key-generations-dialog-button" (click)="keyVerificationStep=true">
      J'ai sauvegardé ma clé de restauration
    </button>
  </mat-dialog-actions>
</div>


<div *ngIf="keyVerificationStep">
  <div class="title-icon">
    <app-icon icon="restoration-key"></app-icon><div class="title">Test de la clé de restauration</div>
  </div>
  <mat-dialog-content>
    Afin de vous assurer qu'il n'y a eu pas d'erreur de copie lors de la sauvegarde de la clé, nous vous recommandons dès maintenant de vérifier votre clé de restauration.<br><br>
    Veuillez saisir votre clé de restauration en la recopiant à partir du support où vous l'avez enregistrée :
    <mat-form-field appearance="outline" floatLabel="always" data-test=restoration-key>
      <input matInput [formControl]="restorationKeyControl" placeholder="ex: ab12-34cd-efgh-i5j6-kl78" required
        data-test=restoration-key-input autocomplete="off" autofocus>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-flat-button color="accent" data-test="check-restoration-key-button" (click)="checkRestorationKey()">
      Vérifier la validité de ma clé
    </button>
    <button mat-flat-button color="transparent" (click)="keyVerificationStep=false">Revoir ma clé</button>
    <button mat-flat-button color="transparent" mat-dialog-close data-test="close-no-check">Fermer sans vérifier ma clé de restauration</button>
  </mat-dialog-actions>
</div>
