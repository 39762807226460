import { Injectable } from "@angular/core";
import { BeneficiariesAPIService, BeneficiaryInput, BeneficiaryOutput } from "src/sdk/solal-core-api-sdk";
import { AuthService } from "../../auth/services/auth.service";
import { UserStatusService } from "../../auth/services/user-status.service";

@Injectable({
  providedIn: 'root'
})
export class BeneficiariesService {

  private beneficiariesMap?: Map<string, BeneficiaryOutput>;

  constructor(
    private beneficiariesApi: BeneficiariesAPIService,
    authService: AuthService,
    private userStatusService: UserStatusService,
  ) {
    authService.sessionUserObs.subscribe(user => {
      // Reset cache on user logout
      if (user === null) {
        this.beneficiariesMap = undefined;
      }
    });
  }

  async createBeneficiary(beneficiary: BeneficiaryInput): Promise<BeneficiaryOutput> {
    const newBeneficiary = await this.beneficiariesApi.createBeneficiary(beneficiary).toPromise();
    this.beneficiariesMap?.set(newBeneficiary._id, newBeneficiary);
    return newBeneficiary;
  }

  async getBeneficiariesMap(forceRefresh = false): Promise<Map<string, BeneficiaryOutput>> {
    if (forceRefresh || this.beneficiariesMap === undefined) {
      const beneficiaries = await this.beneficiariesApi.listBeneficiaries().toPromise();
      this.beneficiariesMap = new Map(beneficiaries.map(beneficiary => [ beneficiary._id, beneficiary ]));
    }
    return this.beneficiariesMap;
  }

  async listBeneficiaries(forceRefresh = false): Promise<BeneficiaryOutput[]> {
    return Array.from((await this.getBeneficiariesMap(forceRefresh)).values());
  }

  async getBeneficiary(beneficiaryId: string): Promise<BeneficiaryOutput | undefined> {
    if (!this.beneficiariesMap) {
      await this.getBeneficiariesMap();
    }
    return this.beneficiariesMap?.get(beneficiaryId);
  }

  async updateBeneficiary(beneficiaryId: string, beneficiary: BeneficiaryInput): Promise<BeneficiaryOutput> {
    const updatedBeneficiary = await this.beneficiariesApi.updateBeneficiary(beneficiaryId, beneficiary).toPromise();
    this.beneficiariesMap?.set(updatedBeneficiary._id, updatedBeneficiary);
    void this.userStatusService.refreshUserStatus();
    return updatedBeneficiary;
  }

  async deleteBeneficiary(beneficiaryId: string): Promise<void> {
    await this.beneficiariesApi.deleteBeneficiary(beneficiaryId).toPromise();
    this.beneficiariesMap?.delete(beneficiaryId);
    void this.userStatusService.refreshUserStatus();
  }

}
