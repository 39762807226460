export const AssetsRouteNames = {
  ROOT: 'assets',
  ONBOARDING_WELCOME: 'onboarding-welcome',
  ONBOARDING_SELECT: 'onboarding-select',
  ONBOARDING_INFO: 'onboarding-info',
  LIST: 'list',
  ADD: 'add',
  NEW: 'new',
  NOT_PREMIUM: 'not-premium',

  EDIT_VAULT: 'vault',
  EDIT_WILL: 'will',
  BENEFICIARIES: 'beneficiaries',
  EDIT_BENEFICIARIES: 'beneficiaries/edit',
  DOCUMENTS: 'documents',
};
