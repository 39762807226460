export const ProfileRouteNames = {
  ROOT: 'profile',
  USER_INFO: 'user-info',
  RESTORATION_KEYS: 'restoration-keys',
  CHANGE_PASSWORD: 'change-password',
  OFFICIAL_DOCS: 'official-docs',
  FEES: 'fees',
  MANAGE_SUBSCRIPTIONS: 'manage-subscriptions',
  ACCOUNT_DELETED: 'account-deleted',
};
