<mat-form-field appearance="outline" floatLabel="always" data-test=password>
  <mat-label>{{label}}</mat-label>
  <input [formControl]="passwordControl" matInput type="password" [placeholder]='placeholder' [required]="required" spellcheck="false"
    [type]="getPasswordType()" autocomplete="{{autocomplete}}" id="{{autocomplete}}" data-test=password-input [readonly]="readonly">
  <mat-icon matSuffix aria-hidden="true" (click)="changePasswordVisibility()">{{getPasswordIcon()}}</mat-icon>
  <mat-error *ngIf="passwordControl.errors?.required">Ce champ est requis</mat-error>
  <mat-error *ngIf="passwordControl.errors?.minlength">Le mot de passe est trop court (min 8 caractères)</mat-error>
  <mat-error *ngIf="passwordControl.errors?.maxlength">Le mot de passe est trop long (max 255 caractères)</mat-error>
  <mat-error *ngIf="passwordControl.errors?.strength">{{passwordControl.errors?.strength}}</mat-error>
</mat-form-field>
