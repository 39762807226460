import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthRouterService } from '../services/auth-router.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UnauthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private authRouterService: AuthRouterService,
  ) {
  }

  async canActivate(): Promise<boolean> {
    const isLoggedIn = await this.authService.isLoggedIn();
    if (isLoggedIn) {
      await this.authRouterService.redirectAfterLogin();
      return false;
    }
    return true;
  }

}
