export type AssociativeArray = { [key: string]: object|number|string; };
export type ErrorFormator = (data: AssociativeArray) => string;

export const defaultAppErrorCodesMap = new Map<string, string|ErrorFormator>([
  // API errors
  ['userDeclaredDead', 'Le propriétaire de ce compte a été déclaré mort. Prévenez rapidement le support s\'il s\'agit de vous.'],
  ['emailAlreadyUsed', 'Cette adresse email est déjà utilisée pour un compte utilisateur'],
  ['invalidNotaryInvitation', 'L\'invitation de votre notaire est invalide ou a expiré'],
  ['userNotFound', "Aucun utilisateur n'a été trouvé avec cet identifiant"],
  ['InvalidNotaryUser', 'Seul les notaires peuvent effectuer cette action'],
  ['unknownEmail', 'Aucun compte n\'a été trouvé lié à cette adresse email'],
  ['invalidNotaryEmail', 'Aucun notaire n\'a été trouvé lié à cette adresse email'],
  ['invalidConfirmationCode', 'Le code de confirmation est invalide ou a expiré'],
  ['invalidPassword', 'Le mot de passe est incorrect'],
  ['emailNotConfirmed', 'Vous devez valider votre adresse email avant de pouvoir vous connecter'],
  ['sendMailError', 'Impossible d\'envoyer l\'email, veuillez vérifier que l\'adresse est correcte ou réessayer plus tard'],
  ['tooManyRequests', (data: AssociativeArray): string => `Trop de tentatives, veuillez attendre ${data['retryAfter']} secondes`],
  ['benefeciaryIsAGuardian', 'Vous devez le supprimer en premier lieu de la liste des gardiens'],
  ['InvalidGuardianUser', 'Vous ne pouvez pas être votre propre gardien'],
  ['alreadyGardian', 'Vous êtes déjà gardien de cet utilisateur'],
  ['userAlreadyNotaryPremium', 'Votre abonnement notaire premium est déjà actif'],
  ['alreadyInvited', 'Une invitation a déjà été envoyée à cette adresse email'],
  ['notaryEmailAddress', 'Cette adresse email ne peut être utilisée que pour s\'inscrire en tant que notaire'],
  ['notaryEmailDomain', 'Le domaine notaires.fr ne peut être utilisé que pour les inscriptions en tant que notaire'],
  ['notSelfAssignation', 'Avec un compte de démonstration, vous ne pouvez choisir un autre notaire que vous même'],
  ['fileTooBig', (data: AssociativeArray): string => {
    let maxSizeInMB = '';
    if (typeof data['maxFileSize'] === 'number') {
      maxSizeInMB = `, taille maximum : ${data['maxFileSize']/(1000**2)} Mo`;
    }
    return 'Le fichier est trop gros pour être téléversé' + maxSizeInMB;
  }],
  ['invalidFileType', 'Seul le format de fichier "pdf" peut être utilisé pour décrire vos honoraires.'],
  ['notEnoughSpaceAvailable', (data: AssociativeArray): string => {
    let limit = '';
    if (typeof data['limit'] === 'number') {
      limit = ` de ${data['limit']/1000**2} Mo`;
    }
    return `Vous avez atteint la limite de stockage${limit} à cet emplacement`;
  }],
  ['invalidSigningPhoneNumber', 'Le numéro de téléphone enregistré dans votre profil ne peut être utilisé pour signer un testament'],
  ['cantDeclareOwnDeath', 'Vous ne pouvez déclarer votre propre mort'],
  ['invalidJsonInput', (data: AssociativeArray): string => `Le contenu du fichier ne respecte pas les contraintes :\n${JSON.stringify(data)}`],
  ['userDeletionNotSupported', 'Ce type de compte ne peut pas être supprimé'],

  // Portal internal errors
  ['canUpdateOwnAdminPermission', "Vous ne pouvez enlever vos propres droits d'administrateur"],
  ['Network Error', 'Une erreur réseau est survenue en tentant de joindre le serveur. Veuillez vérifier votre connexion et/ou réessayer ultérieurement.'],
  ['invalidAssetTemplateName', (data: AssociativeArray): string => `Nom de biens ou actifs invalide à la ligne ${data['line']} : "${data['name']}"`],
  ['invalidAssetTemplateCategory', (data: AssociativeArray): string => `Catégorie de biens ou actifs invalide à la ligne ${data['line']} : "${data['category']}"`],
  ['tooManyColumnInAssetTemplate', (data: AssociativeArray): string => `La ligne ${data['line']} comporte plus de colonne qu'attendu` ],
  ['addressLocationNotFound', 'Impossible de déterminer les coordonnées GPS pour cette adresse'],

  // Http errors
  ['401', 'Votre session a expiré, veuillez rafraichir la page pour vous reconnecter'],
  ['403', 'Vous n\'avez pas les permissions suffisantes pour exécuter cette action'],
]);

export const fallbackErrorMsg = 'Désolé, un problème technique est survenu, nous vous invitons à réessayer ultérieurement.\nContactez le support si le problème persiste.';
