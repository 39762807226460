import { NgModule } from '@angular/core';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';
import { ForgottenPasswordComponent } from './pages/forgotten-password/forgotten-password.component';
import { LoginComponent } from './pages/login/login.component';

import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { EmailFieldComponent } from './components/email-field/email-field.component';
import { AuthPageComponent } from './components/auth-page/auth-page.component';
import { CoreModule } from '../core/core.module';
import { VerifyDeviceComponent } from './pages/verify-device/verify-device.component';

@NgModule({
  declarations: [
    AuthPageComponent,
    EmailFieldComponent,
    PasswordFieldComponent,

    ConfirmEmailComponent,
    ForgottenPasswordComponent,
    LoginComponent,
    VerifyDeviceComponent,
  ],
  imports: [
    CoreModule,
  ],
  exports: [
    AuthPageComponent,
    ConfirmEmailComponent,
    LoginComponent,

    EmailFieldComponent,
    PasswordFieldComponent,
  ]
})
export class AuthModule { }
