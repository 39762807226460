<div class="left-panel mat-typography">
  <div class="app-logo-container">
    <app-logo></app-logo>
  </div>
  <div class="message">
    <p>
      Bonjour {{invitation?.guardian?.firstName | uppercase }} {{invitation?.guardian?.lastName | uppercase }} !
    </p>
    <p>
      {{invitation?.guardedUser?.firstName | uppercase }} {{invitation?.guardedUser?.lastName | uppercase }} via son compte SOLAL TECH vous propose de devenir le gardien de ses biens et actifs numériques.
    </p>
    <div>
      Pour l’accompagner dans la transmission de son patrimoine numérique, vous devez dans un premier temps activer votre rôle de gardien :
      <ul>
        <li>Identifiez-vous si vous possédez déjà un compte ;</li>
        <li>Créer un compte sur la plateforme SOLAL TECH.</li>
      </ul>
    </div>
  </div>
  <div class="citation">
    “SOLAL TECH est votre solution technique pour répertorier, sécuriser et transmettre vos informations importantes et préserver votre patrimoine numérique.”
    <div class="author">Flora Minaire, fondatrice de Solal</div>
  </div>
  <ng-content></ng-content>
</div>
