import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

// Check that email is not something like "user@first-level-domain"
export const EMAIL_NOT_FIRST_LEVEL_DOMAIN = /@[^@]+\.[^@]+$/;

export const EMAIL_VALIDATORS_NOT_REQUIRED = [ Validators.email, Validators.pattern(EMAIL_NOT_FIRST_LEVEL_DOMAIN), Validators.maxLength(255) ];
export const EMAIL_VALIDATORS_REQUIRED = [ Validators.required, ...EMAIL_VALIDATORS_NOT_REQUIRED, ];

@Component({
  selector: 'app-email-field',
  templateUrl: './email-field.component.html',
  styleUrls: ['./email-field.component.scss']
})
export class EmailFieldComponent {

  @Input() emailControl!: FormControl;

}
