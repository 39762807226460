<app-auth-page>

  <div page-title>Se connecter</div>

  <form [formGroup]="formGroup" data-test=login-form (ngSubmit)="onSubmit()" class=one-column-responsive>

    <app-email-field [emailControl]="emailControl" data-test=login-email></app-email-field>

    <app-password-field [passwordControl]="passwordControl" data-test=login-password></app-password-field>

    <div class="form-error" *ngIf="errorMessage">{{errorMessage}}</div>

    <button type="submit" mat-flat-button color="accent" class="form-action" data-test="login-button"
      [disabled]="formGroup.invalid">Continuer</button>

    <div class="form-link"><a [routerLink]="forgottenPasswordLink" data-test=forgotten-password-link>Mot de passe oublié</a></div>

    <div class="form-link"><a [routerLink]="signupLink" data-test=register-link>Créer votre compte</a></div>

  </form>

</app-auth-page>
