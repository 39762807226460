import { Injectable } from "@angular/core";
import { notaryPermissions, PermissionFlag } from "@solal-tech/solal-common";
import { BehaviorSubject } from "rxjs";
import { AdminRouteNames } from "../../admin/admin.routes.names";
import { AssetsRouteNames } from "../../assets/assets.routes.names";
import { AssetsService } from "../../assets/services/assets.service";
import { DoOnceOnConcurrentCall } from "../../core/utils/concurrent";
import { GuardianRouteNames } from "../../guarded-users/guarded-users.routes.names";
import { GuardedUsersService } from "../../guarded-users/services/guarded-users.service";
import { NotaryRouteNames } from "../../notary/notary.routes.names";
import { AuthService } from "./auth.service";

export enum Role {
  testator = 'testator',
  guardian = 'guardian',
  notary = 'notary',
  admin = 'admin',
}

export const RoleLabels = new Map<string, string>([
  [ Role.testator, 'Testateur' ],
  [ Role.guardian, 'Gardien' ],
  [ Role.notary, 'Notaire' ],
  [ Role.admin, 'Admin' ],
]);

export const RoleLabelsAsNotary = new Map<string, string>([
  [ Role.testator, 'Compte Démo' ],
  [ Role.guardian, 'Compte Gardien' ],
  [ Role.notary, 'Compte Notaire' ],
  [ Role.admin, 'Compte Admin' ],
]);

const sessionStorageRoleKey = 'role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private roleSub = new BehaviorSubject<Role>(Role.testator);
  public readonly roleObs = this.roleSub.asObservable();

  private rolesAvailablesSub = new BehaviorSubject<Role[]>([ ]);
  public readonly rolesAvailablesObs = this.rolesAvailablesSub.asObservable();

  private hasNotaryPermission = false;
  private hasAdminPermission = false;

  private refreshMutex = new DoOnceOnConcurrentCall<void>();

  constructor(
    private authService: AuthService,
    private guardedUsersService: GuardedUsersService,
    private assetsService: AssetsService,
  ) {
    this.authService.sessionUserObs.subscribe(async (user) => {
      if (user) {
        await this.refreshAvailableRoles();
      }
    });
  }

  changeRole(newRole: Role): void {
    if (this.roleSub.value !== newRole && this.rolesAvailablesSub.value.includes(newRole)) {
      this.roleSub.next(newRole);
    }
    // We update the session storage in every case so that even if the authentication
    // is not performed yet, the right role will be selected after this authentication
    sessionStorage.setItem(sessionStorageRoleKey, newRole);
  }

  getRoleLabel(role = this.roleSub.value): string {
    if (this.hasNotaryPermission) {
      return RoleLabelsAsNotary.get(role)!;
    } else {
      return RoleLabels.get(role)!;
    }
  }

  async refreshAvailableRoles(): Promise<void> {
    await this.refreshMutex.run(async () => {
      const roles = await this.getAvailableRoles();
      if (JSON.stringify(this.rolesAvailablesSub.value) !== JSON.stringify(roles)) {
        this.rolesAvailablesSub.next(roles);
        await this.selectDefaultRole(roles);
      }
    });
  }

  async getDefaultPage(): Promise<string[]> {
    await this.refreshAvailableRoles();
    const currentRole = this.roleSub.value;
    if (currentRole === Role.admin) {
      return [ AdminRouteNames.ROOT ];
    } else if (currentRole === Role.notary) {
      return [ NotaryRouteNames.ROOT ];
    } else if (currentRole === Role.guardian) {
      return [ GuardianRouteNames.ROOT ];
    } else {
      return [ AssetsRouteNames.ROOT ];
    }
  }


  private async getAvailableRoles(): Promise<Role[]> {
    const hasGuardedUsers = (await this.guardedUsersService.listGuardedUsers()).length > 0;
    this.hasNotaryPermission = await this.authService.hasPermission(notaryPermissions);
    this.hasAdminPermission = await this.authService.hasPermission(PermissionFlag.AdminSpaceAccess);
    const roles = [ Role.testator ];
    if (hasGuardedUsers) {
      roles.push(Role.guardian);
    }
    if (this.hasNotaryPermission) {
      roles.push(Role.notary);
    }
    if (this.hasAdminPermission) {
      roles.push(Role.admin);
    }
    return roles;
  }

  private async selectDefaultRole(availableRoles: Role[]): Promise<void> {
    const lastRoleName = sessionStorage.getItem(sessionStorageRoleKey) as keyof typeof Role;
    const lastRole = Role[lastRoleName];
    const hasAssets = (await this.assetsService.listAssets()).length > 0;
    const primaryRoles = hasAssets
      ? [ lastRole, Role.admin, Role.notary, Role.testator ]
      : [ lastRole, Role.admin, Role.notary, Role.guardian, Role.testator ];
    for (const role of primaryRoles) {
      if (role && availableRoles.includes(role)) {
        this.changeRole(role);
        return;
      }
    }
  }

}
