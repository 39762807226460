import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GuardianInvitation } from 'src/sdk/solal-core-api-sdk/model/guardianInvitation';
import { Subscription } from 'rxjs';
import { mobileOrTablet } from 'src/app/modules/core/utils/responsive';
import { GuardiansService } from 'src/app/modules/guardians/services/guardians.service';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit, OnDestroy {

  invitation?: GuardianInvitation;
  @Input() testator?: boolean = true;


  mobileOrTablet = false;

  private subscriptions = new Subscription();

  constructor(
    private guardiansService: GuardiansService,
    private breakpointObserver: BreakpointObserver,
  ) { }

  async ngOnInit(): Promise<void> {
    this.invitation = await this.guardiansService.getGuardianInvitation();

    this.subscriptions.add(
      this.breakpointObserver.observe([ mobileOrTablet ]).subscribe(state => {
        this.mobileOrTablet = state.matches;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
