const minuteInMs = 60_000;

/**
 * Configure the delay for the dialog that will open to warn the user when
 * the session will expire and to inform him it has expired.
 *
 * The delay is reinitialized every time the refresh token is used, which means
 * every time the accessToken is renew.
 * 
 * sessionExpirationDelayInMs should be less or equal to server 'sessionTokenExpTimeInMs'.
 */
export const notifySessionWillExpireDelayInMs = 12 * minuteInMs; // Display a warning dialog before refresh token expire
export const sessionExpirationDelayInMs = 15 * minuteInMs; // Logout from current session


/**
 * Configure how often the access token should be renew.
 * It will be renew on user action after this delay has been reach since the last accessToken retrieval.
 *
 * Should be less than server 'accessTokenExpTime' in order to refresh the accessToken
 * before it expired (else extra calls will be done by the http-auth interceptor to handle
 * the unauthentified error linked to the token expiration).
 */
export const accessTokenExpirationDelayInMS = 3 * minuteInMs;
