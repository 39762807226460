/**
 * coreAPI
 * API core
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TestamentSignature } from './testamentSignature';
import { Person } from './person';
import { TestamentAsset } from './testamentAsset';


export interface TestamentOutput { 
    _id: string;
    testator: Person;
    notary?: Person;
    beneficiaries: Array<Person>;
    assets: Array<TestamentAsset>;
    createdAt: string;
    status: TestamentOutput.StatusEnum;
    signature: TestamentSignature;
    downloadUrl?: string;
}
export namespace TestamentOutput {
    export type StatusEnum = 'waitingSignature' | 'signed' | 'cancelled' | 'registered' | 'archivedS' | 'archivedR';
    export const StatusEnum = {
        WaitingSignature: 'waitingSignature' as StatusEnum,
        Signed: 'signed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        Registered: 'registered' as StatusEnum,
        ArchivedS: 'archivedS' as StatusEnum,
        ArchivedR: 'archivedR' as StatusEnum
    };
}


