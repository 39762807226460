import { BeneficiariesService } from 'src/app/modules/beneficiaries/services/beneficiaries.service';
import { Injectable } from "@angular/core";
import { GuardiansAPIService, BeneficiaryInput, BeneficiaryOutput, GuardianOutput, GuardianRestoreCreationInput, GuardianRestoreOutput } from "src/sdk/solal-core-api-sdk";
import { UserStatusService } from '../../auth/services/user-status.service';
import { GuardianInvitation } from 'src/sdk/solal-core-api-sdk/model/guardianInvitation';

@Injectable({
  providedIn: 'root'
})
export class GuardiansService {

  private guardianInvitation?: GuardianInvitation;

  constructor(
    private beneficiariesService: BeneficiariesService,
    private guardiansApi: GuardiansAPIService,
    private userStatusService: UserStatusService,
  ) { }

  async createGardian(beneficiary: BeneficiaryInput & { _id?: string }, message?: string): Promise<BeneficiaryOutput> {
    if (!beneficiary._id) {
      beneficiary = await this.beneficiariesService.createBeneficiary(beneficiary);
    }
    await this.inviteGuardian(beneficiary as BeneficiaryOutput, message);
    void this.userStatusService.refreshUserStatus();
    return beneficiary as BeneficiaryOutput;
  }

  async inviteGuardian(beneficiary: BeneficiaryOutput, message?: string): Promise<void> {
    await this.guardiansApi.inviteGuardian({ beneficiaryId: beneficiary._id, message }).toPromise();
  }

  async acceptGuardianInvite(guardianId: string, token: string): Promise<void> {
    await this.guardiansApi.acceptGuardianInvite(guardianId, { token }).toPromise();
    this.guardianInvitation = undefined;
  }

  async refuseGuardianInvite(guardianId: string, token: string): Promise<void> {
    await this.guardiansApi.refuseGuardianInvite(guardianId, { token }).toPromise();
    this.guardianInvitation = undefined;
  }

  async listGuardians(): Promise<GuardianOutput[]> {
    return await this.guardiansApi.listGuardians().toPromise();
  }

  async setGuardianKey(guardian: GuardianOutput, encryptedVaultKey: string): Promise<void> {
    await this.guardiansApi.setGuardianKey(guardian._id, { encryptedVaultKey }).toPromise();
    await this.beneficiariesService.listBeneficiaries(true);
    void this.userStatusService.refreshUserStatus();
  }

  async deleteGuardian(guardianId: string): Promise<GuardianOutput[]> {
    const guardians = await this.guardiansApi.deleteGuardian(guardianId).toPromise();
    void this.userStatusService.refreshUserStatus();
    return guardians;
  }

  async getGuardianInvitation(guardianId?: string): Promise<GuardianInvitation | undefined>{
    if(guardianId){
      if(this.guardianInvitation?._id !== guardianId){
        this.guardianInvitation = await this.guardiansApi.getGuardianInvitation(guardianId).toPromise();
      }
    }
    return this.guardianInvitation;
  }

  async initGuardianRestore(guardianId: string, keys: GuardianRestoreCreationInput): Promise<void> {
    await this.guardiansApi.initGuardianRestore(guardianId, keys).toPromise();
  }

  async getGuardianRestore(guardianId: string): Promise<GuardianRestoreOutput> {
    return await this.guardiansApi.getGuardianRestore(guardianId).toPromise();
  }

  async acceptGuardianRestore(guardianId: string, encryptedVaultKey: string): Promise<void> {
    await this.guardiansApi.acceptGuardianRestore(guardianId, { encryptedVaultKey }).toPromise();
  }

}
