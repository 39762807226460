export const NotaryRouteNames = {
  ROOT: 'notary',
  ONBOARDING_WELCOME: 'onboarding-welcome',
  ONBOARDING_INFO: 'onboarding-info',
  CLIENTS: 'clients',
  NOTIFICATIONS: 'notifications',
};

export const NotaryClientsRouteNames = {
  ROOT: 'clients',
  LIST: 'list',
  NEW: 'new',
  STATUS: 'status',
  TESTAMENTS: 'testaments',
  BENEFICIARIES: 'beneficiaries',
  NOTES: 'notes',
  OFFICIAL_DOCS: 'official-docs',
  ASSETS: 'assets',
};
