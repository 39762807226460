import { Injectable } from "@angular/core";
import { UsersAPIService, UserStatus } from "src/sdk/solal-core-api-sdk";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth.service";

const defaultStatus: UserStatus = {
  hasEncryptionKey: true,
  hasRestorationKey: true,
  hasValidGuardian: true,
  hasNotary: true,
  changeSinceTestamentCreation: false,
  changeSinceSignature: false,
  assetsWithMissingWill: [],
  assetsWithMissingBeneficiary: [],
  clientsNotif: [],
};

@Injectable({
  providedIn: 'root'
})
export class UserStatusService {

  private userStatusSub = new BehaviorSubject<UserStatus>(defaultStatus);
  public readonly userStatusObs = this.userStatusSub.asObservable();

  constructor(
    private usersAPI: UsersAPIService,
    authService: AuthService,
  ) {
    authService.sessionUserObs.subscribe(async user => {
      if (user === null) {
        this.userStatusSub.next(defaultStatus);
      } else {
        await this.refreshUserStatus();
      }
    });
  }

  getUserStatus(): UserStatus {
    return this.userStatusSub.value;
  }

  async refreshUserStatus(): Promise<UserStatus> {
    const userStatus = await this.usersAPI.getUserStatus().toPromise();
    this.userStatusSub.next(userStatus);
    return userStatus;
  }

}
