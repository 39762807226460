import { Component, Input } from '@angular/core';
import { GuardianInvitation } from 'src/sdk/solal-core-api-sdk';

@Component({
  selector: 'app-left-panel-guardian-invitation',
  templateUrl: './left-panel-guardian-invitation.component.html',
  styleUrls: [ './left-panel-guardian-invitation.component.scss' ]
})
export class LeftPanelGuardianInvitationComponent {

  @Input() invitation?: GuardianInvitation;

}
