import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  /** Add token in `Authorization` header of API request */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isAuthenticatedUrl(request.url)) {
      return next.handle(request);
    }
    return this.injectAuthorisationHeader(request, next).pipe(
      catchError(error => {
        // On unauthorized error, attemp to refresh the token and try again the request
        if (error.status === 401) {
          this.authService.markAccessTokenAsExpired();
          return this.injectAuthorisationHeader(request, next);
        }
        return throwError(error);
      })
    );
  }

  private injectAuthorisationHeader(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.getAccessToken()).pipe(
      catchError(() => of(null)),
      switchMap(token => {
        if (token) {
          // when the token has been retrieved successfully, the `Authorization` header is injected
          const authenticatedRequest = request.clone({setHeaders: {Authorization: token}});
          return next.handle(authenticatedRequest);
        } else {
          // otherwise (when user is not connected or on error), the request is done without modification
          return next.handle(request);
        }
      })
    );
  }

  private isAuthenticatedUrl(url: string): boolean {
    return url.match("https:\/\/api.*.*.solal.tech/(?!auth/refresh)")?.length == 1
      || url.match("https:\/\/api.*.*.solaltech.fr/(?!auth/refresh)")?.length == 1
      || url.match("https:\/\/localhost:[0-9]*/(?!auth/refresh)")?.length == 1
      || url.match("http:\/\/localhost:[0-9]*/(?!auth/refresh)")?.length == 1;
  }
}
