export const GuardianRouteNames = {
  ROOT: 'guardian',
  GUARDED_USERS: 'guarded-users',
  NOTIFICATIONS: 'notifications',
};

export const GuardedUsersRouteNames = {
  STATUS: 'status',
  NOTARY: 'notary',
  ASSETS: 'assets',
  OFFICIAL_DOCS: 'official-docs',
  REPORT_DEATH: 'report-death',
};


export const GuAssetsRouteNames = {
  STATUS: 'status',
  WILL: 'will',
  BENEFICIARIES: 'beneficiaries',
  DOCUMENTS: 'documents',
  VAULT: 'vault',
};
