import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { errorToUserString, getAppErrorCode } from 'src/app/modules/core/components/error-dialog/error-msg-formator';
import { AuthRoutesNames } from '../../auth.routes.names';
import { EMAIL_VALIDATORS_REQUIRED } from '../../components/email-field/email-field.component';
import { AuthRouterService } from '../../services/auth-router.service';
import { AuthService } from '../../services/auth.service';
import { SessionUser } from '../../services/session-user';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  emailControl = new FormControl('', EMAIL_VALIDATORS_REQUIRED);
  confirmCodeControl = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]);

  formGroup = new FormGroup({
    email: this.emailControl,
    confirmationCode: this.confirmCodeControl,
  });

  signinLink = [ '/', AuthRoutesNames.LOGIN ];

  newConfirmCodeSent = false;

  errorMessage?: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private authRouterService: AuthRouterService,
  ) {}

  ngOnInit(): void {
    const email = this.route.snapshot.paramMap.get('email') || this.authService.getPersistedEmail();
    if (email) {
      this.emailControl.setValue(email);
    }
  }

  async onSubmit(): Promise<void> {
    if (this.formGroup.valid) {
      this.formGroup.disable();
      this.errorMessage = '';
      try {
        const sessionUser = await this.authService.confirmEmail(this.formGroup.getRawValue());
        await this.redirectAfterCodeConfirm(sessionUser);
      } catch (err) {
        this.errorMessage = errorToUserString(err);
      } finally {
        this.formGroup.enable();
      }
    }
  }

  async sendNewConfirmCode(): Promise<void> {
    this.formGroup.disable();
    this.errorMessage = '';
    this.newConfirmCodeSent = true;
    try {
      await this.authService.sendNewConfirmCode(this.formGroup.getRawValue());
    } catch (err) {
      console.log('sendNewConfirmCode error', err);
      this.newConfirmCodeSent = false;
      if (getAppErrorCode(err) === 'emailAlreadyConfirmed') {
        await this.router.navigate([ AuthRoutesNames.AUTH_ROOT, AuthRoutesNames.LOGIN, { email: this.emailControl.value } ]);
      }
      this.errorMessage = errorToUserString(err);
    } finally {
      this.formGroup.enable();
    }
  }

  async redirectAfterCodeConfirm(sessionUser: SessionUser|null): Promise<void> {
    if (sessionUser) {
      await this.authRouterService.redirectAfterLogin();
    } else {
      await this.router.navigate([ AuthRoutesNames.AUTH_ROOT, AuthRoutesNames.LOGIN, { email: this.emailControl.value } ]);
    }
  }

}
