/**
 * coreAPI
 * API core
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Will { 
    choice?: Will.ChoiceEnum;
    info?: string;
}
export namespace Will {
    export type ChoiceEnum = 'delete' | 'transmit' | 'retain';
    export const ChoiceEnum = {
        Delete: 'delete' as ChoiceEnum,
        Transmit: 'transmit' as ChoiceEnum,
        Retain: 'retain' as ChoiceEnum
    };
}


