import { BehaviorSubject, NEVER, Observable, timer } from "rxjs";
import { mapTo, switchMap } from "rxjs/operators";

export class SessionTimer {

  private notificationDate?: Date;
  private expirationDate?: Date;

  private active = new BehaviorSubject<boolean>(false);

  private notificationEvent = this.active.pipe(
    switchMap(started => started ? timer(this.notificationDate).pipe(mapTo(undefined)) : NEVER)
  );

  private expirationEvent = this.active.pipe(
    switchMap(started => started ? timer(this.expirationDate).pipe(mapTo(undefined)) : NEVER)
  );

  constructor(
    private notificationDelayInMs: number,
    private expirationDelayInMs: number,
  ) {
    if (notificationDelayInMs >= expirationDelayInMs) {
      throw new Error('Invalid configuration, notificationDelayInMs >= expirationDelayInMs');
    }
  }

  /**
   * Notify before the session expires
   */
  notificationObs(): Observable<void> {
    return this.notificationEvent;
  }

  /**
   * Notify when the session has expired
   */
  expirationObs(): Observable<void> {
    return this.expirationEvent;
  }

  restart(): void {
    this.notificationDate = new Date(Date.now() + this.notificationDelayInMs);
    this.expirationDate = new Date(Date.now() + this.expirationDelayInMs);
    this.active.next(true);
  }

  cancel(): void {
    this.notificationDate = undefined;
    this.expirationDate = undefined;
    this.active.next(false);
  }

}